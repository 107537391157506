import userApi from "../../services/userApi"


//======================================registration==================================================



export const registration = async(details)=>{
    const {data}=await userApi.post("user/userRegistration",details)
    return data
}

//======================================verifyOTP==================================================


export const verifyOTP = async(details)=>{
    const {data}=await userApi.post("user/verifyOTP",details)
    return data
}

// ====================================resensOTP=====================================================

export const resendOTP = async(details)=>{
    const {data}=await userApi.post("user/resendOTP",details)
    return data
}

// ================================login===============================================================

export const login = async(details)=>{
    const {data}=await userApi.post("user/login",details)
    return data
}

// ====================================forgetPassword=================================================

export const forgetPassword = async(details)=>{
    const {data}=await userApi.post("user/forgetPassword",details)
    return data
}

// ======================================resetPassword==============================================

export const resetPassword = async(details)=>{
    const {data}=await userApi.post("user/resetPassword",details)
    return data
}

//========================================social login==============================================

export const socialLogin = async(details)=>{
    const {data}=await userApi.post("user/socialLogin",details)
    return data
}

// ======================================editProfile================================================

export const editProfile = async(details)=>{
    const {data}=await userApi.put("user/editProfile",details)
    return data
}

// =======================================getUserbyid=================================================

export const getUserById = async(details)=>{
    const {data}=await userApi.get(`user/getUserById`)
    return data
}

// ========================================logOut========================================================


export const logOut = async(details)=>{
    const {data}=await userApi.post(`user/logout`)
    return data
}

// ========================================DELETE=========================================================


export const deleteUser = async(details)=>{
    const {data}=await userApi.delete(`user/deleteUser`)
    return data
}

// ====================================changePassword==============================================

export const changePassword = async(details)=>{
    const {data}=await userApi.post(`user/changePassword`,details)
    return data
}
