import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginScreen from "./Pages/LoginScreen";
import ForgotPassword from "./Pages/ForgotPassword";
import Otp from "./Pages/Otp";
import CreateAccount from "./Pages/CreateAccount";
import Gender from "./Pages/gender";
import Age from "./Pages/Age";
import FocusFemale from "./Pages/FocusFemale";
import MainFemale from "./Pages/MainFemale";
import Motivation from "./Pages/Motivation";
import Pushups from "./Pages/Pushups";
import WeeklyGoal from "./Pages/WeeklyGoal";
import HeightWeight from "./Pages/HeightWeight";
import PlanReady from "./Pages/PlanReady";
import ResetPassword from "./Pages/ResetPassword";
import FocusAreaMale from "./Pages/FocusAreaMale";
import MainGoialMale from "./Pages/MainGoialMale";
import FullBodyChallenge from "./Pages/FullBodyChallenge";
import Home from "./Pages/Home";
import SingleDayDetails from "./Pages/SingleDayDetails";
import SingleExcersiseDetail from "./Pages/SingleExcersiseDetail";
import ExerciseCompleted from "./Pages/ExerciseCompleted";
import FullBodyChallengeDiet from "./Pages/FullBodyChallengeDiet";
import SingleDayDetailsDiet from "./Pages/SingleDayDetailsDiet";
import SingleExcersiseDetailDiet from "./Pages/SingleExcersiseDetailDiet";
import BlogsListing from "./Pages/BlogsListing";
import SingleBLogDetail from "./Pages/SingleBLogDetail";
import AboutUs from "./Pages/AboutUs";
import TermsConditions from "./Pages/TermsConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import ProtectedRoutes from "./Components/ProtectedRoutes";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/login" element={<LoginScreen />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/reset-password/:id" element={<ResetPassword />}></Route>
          <Route path="/otp" element={<Otp />}></Route>

          <Route path="/create-account" element={<CreateAccount />}></Route>

          <Route path="/about-us" element={<AboutUs />}></Route>
          <Route path="/terms-conditions" element={<TermsConditions />}></Route>

          <Route path="/blogs-listing" element={<BlogsListing />}></Route>
          <Route
            path="/single-blogs-detail"
            element={<SingleBLogDetail />}
          ></Route>

          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>

          <Route element={<ProtectedRoutes />}>
            <Route path="/gender" element={<Gender />}></Route>
            <Route path="/age" element={<Age />}></Route>
            <Route path="/focus-female" element={<FocusFemale />}></Route>
            <Route path="/main-female" element={<MainFemale />}></Route>
            <Route path="/motivation" element={<Motivation />}></Route>
            <Route path="/pushups" element={<Pushups />}></Route>
            <Route path="/weekly-goal" element={<WeeklyGoal />}></Route>
            <Route path="/height-weight" element={<HeightWeight />}></Route>
            <Route path="/plan-ready" element={<PlanReady />}></Route>

            <Route path="/focus-area-male" element={<FocusAreaMale />}></Route>
            <Route path="/main-goial-male" element={<MainGoialMale />}></Route>
            <Route
              path="/full-body-challenge"
              element={<FullBodyChallenge />}
            ></Route>
            <Route
              path="/full-body-challenge"
              element={<FullBodyChallenge />}
            ></Route>
            <Route
              path="/single-day-details"
              element={<SingleDayDetails />}
            ></Route>
            <Route
              path="/single-excersise-details"
              element={<SingleExcersiseDetail />}
            ></Route>
            <Route
              path="/exercise-completed"
              element={<ExerciseCompleted />}
            ></Route>
            <Route
              path="/full-body-challenge"
              element={<FullBodyChallenge />}
            ></Route>
            <Route
              path="/single-day-details"
              element={<SingleDayDetails />}
            ></Route>
            <Route
              path="/single-excersise-details"
              element={<SingleExcersiseDetail />}
            ></Route>
            <Route
              path="/exercise-completed"
              element={<ExerciseCompleted />}
            ></Route>
            <Route
              path="/full-body-challenge-diet"
              element={<FullBodyChallengeDiet />}
            ></Route>
            <Route
              path="/single-day-details-diet"
              element={<SingleDayDetailsDiet />}
            ></Route>
            <Route
              path="/single-excersise-details-diet"
              element={<SingleExcersiseDetailDiet />}
            ></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
