import React from "react";
import Layout from "../Components/Layout";
import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

export default function SingleBLogDetail() {
  return (
    <Layout>
      <section className="body-challenge-banner">
        <Container className="mt-5">
          <div className="single-excersise">
            <div className="single-excersise-video">
              <img src={require("../Assets/images/blog-img.png")} />
            </div>
            <div className="single-excersise-details">
              <div className="top-blog-text mt-4 mb-0">
                <div>
                  <p className="mb-0">Dope Anthem</p>
                  <p className="mb-0">24 January 2024</p>
                </div>
              </div>
              <div className="single-excersise-title">
                <h2>Veg Salad with Tangy Twist</h2>
              </div>
              <div className="single-excersise-desc">
                <p>
                  Lorem Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem ipsum has been industry’s standard dummy text
                  ever since 1500s, when an unknown printer took galley of it
                  and scrambled it to make a specimen copy. Lorem Ipsum is
                  simply dummy text for printing and typesetting industry. Lorem
                  Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem ipsum has been industry’s standard dummy text
                  ever since 1500s, when an unknown printer took galley of it
                  and scrambled it to make a specimen copy. Lorem Ipsum is
                  simply dummy text for printing and typesetting industry. Lorem
                  Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem ipsum has been industry’s standard dummy text
                  ever since 1500s, when an unknown printer took galley of it
                  and scrambled it to make a specimen copy. Lorem Ipsum is
                  simply dummy text for printing and typesetting industry.
                </p>
                <p>
                  Lorem Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem ipsum has been industry’s standard dummy text
                  ever since 1500s, when an unknown printer took galley of it
                  and scrambled it to make a specimen copy. Lorem Ipsum is
                  simply dummy text for printing and typesetting industry. Lorem
                  Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem ipsum has been industry’s standard dummy text
                  ever since 1500s, when an unknown printer took galley of it
                  and scrambled it to make a specimen copy. Lorem Ipsum is
                  simply dummy text for printing and typesetting industry. Lorem
                  Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem ipsum has been industry’s standard dummy text
                  ever since 1500s, when an unknown printer took galley of it
                  and scrambled it to make a specimen copy. Lorem Ipsum is
                  simply dummy text for printing and typesetting industry.
                </p>{" "}
                <p>
                  Lorem Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem ipsum has been industry’s standard dummy text
                  ever since 1500s, when an unknown printer took galley of it
                  and scrambled it to make a specimen copy. Lorem Ipsum is
                  simply dummy text for printing and typesetting industry. Lorem
                  Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem ipsum has been industry’s standard dummy text
                  ever since 1500s, when an unknown printer took galley of it
                  and scrambled it to make a specimen copy. Lorem Ipsum is
                  simply dummy text for printing and typesetting industry. Lorem
                  Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem ipsum has been industry’s standard dummy text
                  ever since 1500s, when an unknown printer took galley of it
                  and scrambled it to make a specimen copy. Lorem Ipsum is
                  simply dummy text for printing and typesetting industry.
                </p>{" "}
                <p>
                  Lorem Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem ipsum has been industry’s standard dummy text
                  ever since 1500s, when an unknown printer took galley of it
                  and scrambled it to make a specimen copy. Lorem Ipsum is
                  simply dummy text for printing and typesetting industry. Lorem
                  Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem ipsum has been industry’s standard dummy text
                  ever since 1500s, when an unknown printer took galley of it
                  and scrambled it to make a specimen copy. Lorem Ipsum is
                  simply dummy text for printing and typesetting industry. Lorem
                  Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem ipsum has been industry’s standard dummy text
                  ever since 1500s, when an unknown printer took galley of it
                  and scrambled it to make a specimen copy. Lorem Ipsum is
                  simply dummy text for printing and typesetting industry.
                </p>{" "}
                <p>
                  Lorem Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem ipsum has been industry’s standard dummy text
                  ever since 1500s, when an unknown printer took galley of it
                  and scrambled it to make a specimen copy. Lorem Ipsum is
                  simply dummy text for printing and typesetting industry. Lorem
                  Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem ipsum has been industry’s standard dummy text
                  ever since 1500s, when an unknown printer took galley of it
                  and scrambled it to make a specimen copy. Lorem Ipsum is
                  simply dummy text for printing and typesetting industry. Lorem
                  Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem ipsum has been industry’s standard dummy text
                  ever since 1500s, when an unknown printer took galley of it
                  and scrambled it to make a specimen copy. Lorem Ipsum is
                  simply dummy text for printing and typesetting industry.
                </p>{" "}
                <p>
                  Lorem Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem ipsum has been industry’s standard dummy text
                  ever since 1500s, when an unknown printer took galley of it
                  and scrambled it to make a specimen copy. Lorem Ipsum is
                  simply dummy text for printing and typesetting industry. Lorem
                  Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem ipsum has been industry’s standard dummy text
                  ever since 1500s, when an unknown printer took galley of it
                  and scrambled it to make a specimen copy. Lorem Ipsum is
                  simply dummy text for printing and typesetting industry. Lorem
                  Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem ipsum has been industry’s standard dummy text
                  ever since 1500s, when an unknown printer took galley of it
                  and scrambled it to make a specimen copy. Lorem Ipsum is
                  simply dummy text for printing and typesetting industry.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  );
}
