import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import useMutationHook from "../myHooks/mutationHook";
import { forgetPassword, login } from "../redux/Actions/userActions";

export default function ForgotPassword() {
  const navigate = useNavigate();
  // ==============================================react state============================================

  const [email, setEmail] = useState(null);

  // ============================================post data=============================================

  const { mutate, isPending, isError, isSuccess, data } =
    useMutationHook(forgetPassword);

  useEffect(() => {
    if (isSuccess) {
      console.log(data, "data");
      if (data?.success) {
        navigate(`/otp`, {
          state: {
            purpose: "forgetPassword",
            userId: data?.data?.data?._id,
          },
        });
      }
    }
  }, [isSuccess]);

  // ===========================================handlesubmit=============================================

  const handleSubmit = () => {
    if (!email) {
      toast.error("Please enter an email");
    } else {
      console.log(email);
      mutate({ email });
    }
  };

  return (
    <div>
      <Container fluid>
        <div className="main-form">
          <Row>
            <Col lg={7} className="px-0">
              <div className="left-img">
                <img src={require("../Assets/images/leftimg.png")} />
              </div>
            </Col>
            <Col lg={5} className="form-right">
              <div className="logo">
                <img src={require("../Assets/images/LOGO.png")} />
              </div>
              <div className="cmn-form-wrap">
                <div className="cmn-form">
                  <h2 className="heading-30 mb-2">Forgot Password</h2>
                  <p className="sub-16 width-set">
                    Don’t worry! It occurs. Please enter the email address
                    linked with your account.
                  </p>
                  <div className="cmn-form-fields">
                    <Form.Group className="mb-3 form-inner">
                      <label>Email</label>
                      <Form.Control
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        placeholder="Enter here"
                      />
                    </Form.Group>
                  </div>
                  <Link
                    // to="/otp"
                    onClick={handleSubmit}
                    className="cmn-green-btn mt-5 mb-4"
                  >
                    {isPending ? <div className="loader"></div> : "Send Code"}
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
