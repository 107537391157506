import React from "react";
import Layout from "../Components/Layout";
import { Col, Container, Row } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "react-router-dom";

export default function SingleDayDetailsDiet() {
  return (
    <Layout>
      <section className="body-challenge-banner">
        <Container className="mt-5">
          <div className="full-body-challenge-card">
            <Row className="align-items-center">
              <Col lg={12}>
                <div className="body-challenge-left">
                  <h2>Day 1</h2>
                  <p>Diet Plan</p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="single-day-details-sec">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="single-day-title">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="19"
                    viewBox="0 0 23 19"
                    fill="none"
                  >
                    <path
                      d="M4.56325 14.7689C3.24832 10.9686 4.76501 7.54024 7.00313 5.78475C9.44708 3.86773 12.8128 3.68544 15.3768 5.35552C16.8886 6.3401 17.9464 7.71045 18.519 9.474C19.0903 11.2337 19.0317 12.9886 18.4095 14.7795H18.7817C19.9529 14.7795 21.1238 14.7772 22.2949 14.7812C22.6624 14.7825 22.9293 15.0173 22.9907 15.37C23.0424 15.6666 22.8742 16.0184 22.5939 16.1195C22.4296 16.1787 22.2426 16.1884 22.0659 16.1886C19.1541 16.1925 16.2424 16.1914 13.3306 16.1914C9.23738 16.1914 5.14393 16.1914 1.05067 16.1912C0.934739 16.1912 0.818196 16.1948 0.70267 16.1855C0.270259 16.1507 0.00239681 15.8554 0.0229393 15.4423C0.0422615 15.0525 0.331275 14.7818 0.746193 14.7806C1.94885 14.7774 3.1515 14.7795 4.35416 14.7789C4.41721 14.7789 4.48006 14.7731 4.56325 14.7689Z"
                      fill="#486870"
                    />
                    <path
                      d="M11.4645 18.9997C10.1561 18.9997 8.84748 19.0006 7.53906 18.9993C7.05967 18.9989 6.75011 18.7269 6.74299 18.3055C6.73505 17.8401 7.12475 17.6048 7.71011 17.6048C10.9414 17.6048 12.3541 17.595 15.3332 17.595C15.8712 17.595 16.2261 17.8613 16.2184 18.3062C16.2111 18.7276 15.9017 18.9989 15.4215 18.9993C14.1025 19.0006 12.7835 18.9997 11.4645 18.9997Z"
                      fill="#486870"
                    />
                    <path
                      d="M12.1668 1.39685C12.167 1.62726 12.1762 1.85788 12.165 2.08765C12.1439 2.52387 11.8439 2.83292 11.4657 2.82253C11.0954 2.81236 10.8098 2.51349 10.7974 2.08998C10.784 1.6298 10.7828 1.16835 10.798 0.708388C10.8122 0.282762 11.0984 0.00042441 11.4794 4.79619e-07C11.8609 -0.000423451 12.1402 0.280218 12.1642 0.706056C12.1774 0.935615 12.1668 1.16666 12.1668 1.39685Z"
                      fill="#486870"
                    />
                    <path
                      d="M17.887 5.63531C17.5252 5.46765 17.4033 4.9867 17.6008 4.6289C17.6381 4.56149 17.6789 4.49727 17.7278 4.44046C18.0013 4.12336 18.2981 3.82725 18.5934 3.53134C18.9143 3.20958 19.325 3.19686 19.603 3.48789C19.8847 3.78273 19.8717 4.20306 19.5581 4.53924C19.2556 4.86354 18.9493 5.18488 18.6341 5.49563C18.4408 5.68597 18.1545 5.75083 17.9088 5.64485C17.9013 5.64188 17.8941 5.63849 17.887 5.63531Z"
                      fill="#486870"
                    />
                    <path
                      d="M3.53246 3.35774C3.75924 3.2543 4.02384 3.2986 4.23191 3.43829C4.26364 3.45949 4.29435 3.48323 4.32262 3.50972C4.64256 3.80987 4.94337 4.13269 5.24053 4.45784C5.53809 4.78342 5.54359 5.19442 5.27206 5.48439C4.9924 5.78284 4.58521 5.77245 4.26466 5.44751C3.94818 5.12681 3.63455 4.80271 3.33109 4.46887C3.13482 4.25287 3.07625 3.99046 3.20093 3.7113C3.27313 3.54915 3.37544 3.42939 3.53246 3.35774Z"
                      fill="#486870"
                    />
                    <path
                      d="M1.25569 12.6649C1.05149 12.666 0.847073 12.6715 0.643071 12.6624C0.272492 12.6461 0.010936 12.365 0.000359662 11.9869C-0.0104201 11.6028 0.221855 11.2939 0.597723 11.2744C1.0643 11.2501 1.53394 11.2479 2.00072 11.271C2.43008 11.2925 2.77299 11.7026 2.67577 12.139C2.60987 12.4345 2.37841 12.6397 2.06763 12.6634C2.03997 12.6656 2.01211 12.6645 1.98444 12.6649C1.78431 12.6651 1.45624 12.6649 1.25569 12.6649Z"
                      fill="#486870"
                    />
                    <path
                      d="M21.6158 12.6645C21.3835 12.6645 21.1512 12.6719 20.9194 12.6628C20.5453 12.6484 20.2824 12.3754 20.2667 11.9983C20.2506 11.6125 20.4792 11.2971 20.8521 11.2761C21.3567 11.2477 21.865 11.2477 22.3696 11.2753C22.7442 11.2956 22.9738 11.6074 22.96 11.9932C22.9466 12.3731 22.6871 12.6469 22.3124 12.6626C22.0803 12.6723 21.8479 12.6645 21.6158 12.6645Z"
                      fill="#486870"
                    />
                  </svg>
                </div>
                <p>Morning</p>
              </div>
              <div className="single-day-card">
                <div className="left-side">
                  <div>
                    <img src={require("../Assets/images/blog-img.png")} />
                    <div className="video-detail">
                      <h3> Jumping Jacks</h3>
                      <p>00:20</p>
                    </div>
                  </div>
                  <button>Start</button>
                </div>
                <div className="right-side">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                  >
                    <path
                      d="M9.99191 2.1501C7.06273 2.1501 4.13359 2.15077 1.20441 2.1484C1.03342 2.14825 0.856936 2.1478 0.692521 2.10538C0.228746 1.98572 -0.0366775 1.53457 0.00410644 0.972528C0.042884 0.437868 0.357013 0.0746608 0.834382 0.0185348C0.950507 0.00490157 1.06812 0.000641184 1.18504 0.000604137C7.05899 -0.000210894 12.9329 -0.000359082 18.8069 0.00115984C18.9626 0.00119689 19.1218 0.00149326 19.2734 0.0339834C19.7386 0.133676 20.026 0.581758 19.9981 1.13942C19.9706 1.68998 19.6574 2.07078 19.1768 2.12954C19.0453 2.14562 18.9119 2.14925 18.7794 2.14928C15.8502 2.15036 12.9211 2.1501 9.99191 2.1501Z"
                      fill="#7D7D7D"
                    />
                    <path
                      d="M9.97544 8.5751C7.04626 8.5751 4.11712 8.57588 1.18795 8.5731C1.01737 8.57295 0.840609 8.56928 0.677439 8.52353C0.212765 8.39324 -0.0401712 7.94741 0.00573226 7.38C0.0486263 6.84949 0.365765 6.49518 0.850779 6.44172C0.967008 6.4289 1.08459 6.4256 1.20151 6.42557C7.06764 6.42482 12.9338 6.42457 18.7999 6.42668C18.9708 6.42675 19.1474 6.42783 19.3115 6.47128C19.7765 6.59446 20.0368 7.0418 19.9947 7.60699C19.955 8.14091 19.6408 8.50145 19.1604 8.55643C19.0288 8.57147 18.8955 8.57436 18.7629 8.57439C15.8338 8.57532 12.9046 8.5751 9.97544 8.5751Z"
                      fill="#7D7D7D"
                    />
                    <path
                      d="M10.0014 12.8499C12.9305 12.8499 15.8597 12.8494 18.7888 12.8513C18.9523 12.8514 19.1197 12.8509 19.2786 12.8858C19.7443 12.9882 20.0278 13.4367 19.9977 13.9952C19.9681 14.5456 19.6545 14.9221 19.1713 14.9803C19.0475 14.9952 18.9221 14.9993 18.7973 14.9994C12.9312 15.0002 7.06505 15.0005 1.19891 14.9984C1.02803 14.9983 0.851401 14.9969 0.687401 14.9533C0.223454 14.8301 -0.0379574 14.3803 0.00452154 13.8167C0.0447174 13.2834 0.35985 12.9225 0.839778 12.8682C0.971401 12.8533 1.10475 12.8506 1.23731 12.8506C4.1587 12.8497 7.08006 12.8499 10.0014 12.8499Z"
                      fill="#7D7D7D"
                    />
                  </svg>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="single-day-title">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                  >
                    <path
                      d="M9.31177 0.0135079C9.26003 0.0225864 9.20853 0.0355385 9.15982 0.0551481C8.7273 0.229335 8.53673 0.517306 8.53539 0.997379C8.53405 1.46111 8.53211 1.92484 8.53612 2.38857C8.54086 2.93438 8.93876 3.33698 9.46639 3.33698C9.99402 3.33698 10.3922 2.93438 10.3967 2.38857C10.4007 1.90632 10.3987 1.42395 10.3962 0.941697C10.3958 0.862291 10.3868 0.781189 10.3686 0.703961C10.3159 0.480387 10.2004 0.31891 10.0437 0.19871C9.8994 0.0879519 9.72498 0.0254916 9.54437 0.00467146C9.46688 -0.00416499 9.38853 -4.93829e-05 9.31177 0.0135079Z"
                      fill="#486870"
                    />
                    <path
                      d="M0.0135539 9.76688C0.022785 9.81845 0.0356592 9.86977 0.0553359 9.91831C0.230119 10.3494 0.519074 10.5393 1.00079 10.5406C1.46611 10.5419 1.93143 10.5439 2.39674 10.5399C2.94441 10.5352 3.34839 10.1386 3.34839 9.61279C3.34839 9.08696 2.94441 8.69017 2.39674 8.68569C1.91284 8.68169 1.42882 8.68363 0.944918 8.68617C0.86524 8.68654 0.783858 8.69549 0.706366 8.71365C0.482027 8.76619 0.32 8.8813 0.199389 9.03745C0.0883739 9.18126 0.0255788 9.35508 0.0046875 9.53508C-0.00417916 9.6123 -4.97192e-05 9.6905 0.0135539 9.76688Z"
                      fill="#486870"
                    />
                    <path
                      d="M9.47295 4.7404C12.118 4.75299 14.2631 6.89903 14.2413 9.51038C14.219 12.1542 12.0677 14.2859 9.45072 14.2575C6.79959 14.2287 4.68872 12.1173 4.69175 9.49731C4.69455 6.86865 6.84623 4.72793 9.47295 4.7404Z"
                      fill="#486870"
                    />
                    <path
                      d="M17.3215 10.4256C17.0676 10.4257 16.8135 10.4296 16.5595 10.425C16.0531 10.4158 15.654 10.0145 15.6494 9.51535C15.6446 9.00017 16.0346 8.58256 16.545 8.5742C17.0654 8.56561 17.5859 8.56549 18.1063 8.57445C18.6065 8.58304 19.0006 8.99993 19 9.50094C18.9994 10.0014 18.6022 10.413 18.1023 10.4245C17.842 10.4303 17.5817 10.4254 17.3215 10.4256Z"
                      fill="#486870"
                    />
                    <path
                      d="M8.53624 17.335C8.53637 17.0756 8.53102 16.8162 8.53746 16.557C8.55009 16.047 8.96306 15.657 9.47915 15.6608C9.98115 15.6643 10.3876 16.0587 10.3948 16.564C10.4023 17.0764 10.4027 17.5892 10.3945 18.1016C10.3865 18.603 9.97349 18.9981 9.4721 19C8.96063 19.0019 8.54887 18.6059 8.53709 18.0946C8.53138 17.8414 8.53624 17.5882 8.53624 17.335Z"
                      fill="#486870"
                    />
                    <path
                      d="M5.2772 4.10986C5.36404 4.30693 5.38141 4.53038 5.3242 4.73798C5.24622 5.02074 5.08516 5.20679 4.83131 5.32687C4.49547 5.48569 4.14846 5.46499 3.87432 5.21696C3.44835 4.83179 3.03794 4.42604 2.65315 4.00032C2.23848 3.54143 2.47035 2.72121 3.13462 2.54618C3.45127 2.46278 3.79075 2.56082 4.03173 2.78186C4.42296 3.14076 4.79815 3.51915 5.15488 3.91195C5.20748 3.96981 5.24647 4.04014 5.2772 4.10986Z"
                      fill="#486870"
                    />
                    <path
                      d="M14.9215 5.30169C14.6915 5.4162 14.4219 5.43327 14.1785 5.35108C13.939 5.2701 13.7744 5.12267 13.6624 4.89703C13.4933 4.55629 13.511 4.20549 13.7627 3.9266C14.1471 3.50051 14.5555 3.09258 14.9829 2.70935C15.4611 2.28036 16.331 2.54557 16.4594 3.26496C16.512 3.55958 16.4098 3.86244 16.2066 4.08275C15.8469 4.47252 15.4673 4.84607 15.0736 5.20135C15.0282 5.24214 14.9756 5.2747 14.9215 5.30169Z"
                      fill="#486870"
                    />
                    <path
                      d="M4.01084 13.6946C4.24332 13.5791 4.51697 13.564 4.76244 13.6489C5.00124 13.7314 5.16582 13.8831 5.27623 14.1122C5.44093 14.4538 5.41627 14.803 5.16205 15.0817C4.77921 15.5012 4.37632 15.9055 3.95412 16.2855C3.6065 16.5984 3.05081 16.547 2.72202 16.2176C2.39237 15.8874 2.3433 15.3365 2.65849 14.9896C3.04037 14.5693 3.44763 14.1696 3.86898 13.7884C3.91112 13.7503 3.96019 13.7198 4.01084 13.6946Z"
                      fill="#486870"
                    />
                    <path
                      d="M16.3633 15.1322C16.4797 15.3657 16.4966 15.6402 16.4123 15.887C16.3314 16.1237 16.1815 16.2848 15.9578 16.392C15.6194 16.5542 15.2647 16.5431 14.9838 16.289C14.5578 15.904 14.1465 15.4989 13.763 15.0721C13.4474 14.721 13.5057 14.173 13.8423 13.8463C14.1807 13.5176 14.7225 13.4747 15.0747 13.7932C15.4917 14.17 15.8897 14.5703 16.2669 14.9866C16.3061 15.0299 16.3375 15.0804 16.3633 15.1322Z"
                      fill="#486870"
                    />
                  </svg>
                </div>
                <p>Lunch</p>
              </div>
              <div className="single-day-card">
                <div className="left-side">
                  <div>
                    <img src={require("../Assets/images/blog-img.png")} />
                    <div className="video-detail">
                      <h3> Jumping Jacks</h3>
                      <p>00:20</p>
                    </div>
                  </div>
                  {/* <button>Start</button> */}
                </div>
                <div className="right-side">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                  >
                    <path
                      d="M9.99191 2.1501C7.06273 2.1501 4.13359 2.15077 1.20441 2.1484C1.03342 2.14825 0.856936 2.1478 0.692521 2.10538C0.228746 1.98572 -0.0366775 1.53457 0.00410644 0.972528C0.042884 0.437868 0.357013 0.0746608 0.834382 0.0185348C0.950507 0.00490157 1.06812 0.000641184 1.18504 0.000604137C7.05899 -0.000210894 12.9329 -0.000359082 18.8069 0.00115984C18.9626 0.00119689 19.1218 0.00149326 19.2734 0.0339834C19.7386 0.133676 20.026 0.581758 19.9981 1.13942C19.9706 1.68998 19.6574 2.07078 19.1768 2.12954C19.0453 2.14562 18.9119 2.14925 18.7794 2.14928C15.8502 2.15036 12.9211 2.1501 9.99191 2.1501Z"
                      fill="#7D7D7D"
                    />
                    <path
                      d="M9.97544 8.5751C7.04626 8.5751 4.11712 8.57588 1.18795 8.5731C1.01737 8.57295 0.840609 8.56928 0.677439 8.52353C0.212765 8.39324 -0.0401712 7.94741 0.00573226 7.38C0.0486263 6.84949 0.365765 6.49518 0.850779 6.44172C0.967008 6.4289 1.08459 6.4256 1.20151 6.42557C7.06764 6.42482 12.9338 6.42457 18.7999 6.42668C18.9708 6.42675 19.1474 6.42783 19.3115 6.47128C19.7765 6.59446 20.0368 7.0418 19.9947 7.60699C19.955 8.14091 19.6408 8.50145 19.1604 8.55643C19.0288 8.57147 18.8955 8.57436 18.7629 8.57439C15.8338 8.57532 12.9046 8.5751 9.97544 8.5751Z"
                      fill="#7D7D7D"
                    />
                    <path
                      d="M10.0014 12.8499C12.9305 12.8499 15.8597 12.8494 18.7888 12.8513C18.9523 12.8514 19.1197 12.8509 19.2786 12.8858C19.7443 12.9882 20.0278 13.4367 19.9977 13.9952C19.9681 14.5456 19.6545 14.9221 19.1713 14.9803C19.0475 14.9952 18.9221 14.9993 18.7973 14.9994C12.9312 15.0002 7.06505 15.0005 1.19891 14.9984C1.02803 14.9983 0.851401 14.9969 0.687401 14.9533C0.223454 14.8301 -0.0379574 14.3803 0.00452154 13.8167C0.0447174 13.2834 0.35985 12.9225 0.839778 12.8682C0.971401 12.8533 1.10475 12.8506 1.23731 12.8506C4.1587 12.8497 7.08006 12.8499 10.0014 12.8499Z"
                      fill="#7D7D7D"
                    />
                  </svg>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="single-day-title">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="19"
                    viewBox="0 0 21 19"
                    fill="none"
                  >
                    <path
                      d="M0.136925 5.83758C0.162869 5.75827 0.192336 5.68078 0.22805 5.60642C0.361615 5.32804 0.538742 5.06332 0.737648 4.82903C0.929027 4.60363 1.14715 4.6209 1.3326 4.85535C1.49948 5.06628 1.64458 5.29875 1.77558 5.53567C2.00475 5.95011 2.20334 6.38249 2.4378 6.79347C2.67194 7.20413 2.9341 7.59784 3.18858 8.00603C3.16392 8.00965 3.12132 8.02117 3.07856 8.02133C2.21023 8.02232 1.34205 8.01936 0.473722 8.02528C0.353449 8.02611 0.276575 7.98826 0.234936 7.87918C0.221964 7.84513 0.209153 7.81123 0.196341 7.77718C-0.037158 7.15675 -0.0691884 6.46821 0.136925 5.83758Z"
                      fill="#4B6C74"
                    />
                    <path
                      d="M16.4288 13.2577C16.2169 13.7985 15.5614 13.9691 15.1009 13.6286C15.098 13.6264 15.0952 13.6244 15.0924 13.6223C14.16 12.9357 13.1618 12.7325 12.1141 13.312C11.2741 13.7765 10.8729 14.5706 10.7289 15.5086C10.5331 16.7848 10.8708 17.9108 11.6797 18.8932C11.6962 18.9133 11.7092 18.9362 11.7382 18.9788C8.91042 18.9788 6.10459 18.9788 3.26513 18.9788C3.26513 18.9074 3.26513 18.8338 3.26513 18.7603C3.26513 18.1489 3.26177 17.5377 3.2677 16.9263C3.26898 16.7898 3.23358 16.6953 3.12949 16.6022C1.55265 15.1929 0.588387 13.427 0.282661 11.3031C0.232695 10.956 0.210914 10.6046 0.19538 10.25C0.172639 9.73323 0.573014 9.30053 1.07669 9.30053H16.1466C16.641 9.30053 17.0444 9.71809 17.0291 10.2258C16.9975 11.2729 16.8101 12.2845 16.4288 13.2577Z"
                      fill="#4B6C74"
                    />
                    <path
                      d="M17.6757 15.9484C17.3692 16.732 17.0829 17.488 16.5191 18.0772C15.7752 18.8549 14.8896 19.207 13.8389 18.8755C12.7644 18.5364 12.1578 17.7401 11.9356 16.6333C11.8333 16.1236 11.8903 15.6103 12.0948 15.1272C12.4247 14.348 13.1691 14.0354 13.9229 14.3855C14.1981 14.5134 14.4409 14.7189 14.6896 14.9015C15.0227 15.146 15.3339 15.424 15.6784 15.6493C16.245 16.0196 16.8691 16.1162 17.5268 15.9632C17.5656 15.9543 17.6061 15.9551 17.6757 15.9484Z"
                      fill="#4B6C74"
                    />
                    <path
                      d="M14.0221 5.58815C13.5765 6.49667 13.1257 7.37063 12.2529 7.90107C12.1371 7.97148 11.988 8.01475 11.8535 8.01706C11.1701 8.02857 10.4865 8.02676 9.80309 8.01804C9.68763 8.01656 9.55774 7.98267 9.46005 7.92097C8.30137 7.19015 7.84158 5.54752 8.42597 4.29465C8.74242 3.61614 9.51658 3.05149 10.4511 3.69133C10.6663 3.83875 10.8699 4.0064 11.066 4.17997C11.2781 4.3677 11.472 4.57681 11.6791 4.77062C12.4355 5.47924 12.9826 5.67239 14.0221 5.58815Z"
                      fill="#4B6C74"
                    />
                    <path
                      d="M7.86031 2.1489C8.15067 1.09609 9.20718 0.119957 10.1634 0.0169637C11.3441 -0.110215 12.4017 0.484219 12.9462 1.58128C13.3754 2.44587 13.3382 3.58176 12.8387 4.15957C12.6965 4.03585 12.5452 3.91903 12.4111 3.78445C12.1376 3.50953 11.89 3.20516 11.6019 2.94816C10.7545 2.19217 9.80117 1.92333 8.71599 2.34896C8.67307 2.36574 8.61975 2.38548 8.57955 2.3738C8.33836 2.30372 8.09958 2.22474 7.86031 2.1489Z"
                      fill="#4B6C74"
                    />
                    <path
                      d="M3.23823 5.58766C4.22427 5.74232 5.0058 5.344 5.6914 4.65628C6.02724 4.31933 6.37412 3.98945 6.74695 3.69808C6.9433 3.54457 7.20626 3.48074 7.46186 3.4067C7.08039 4.15546 6.90918 4.92758 6.95002 5.74857C6.99134 6.5786 7.27449 7.31815 7.77143 8.00324C7.70609 8.01081 7.65756 8.0215 7.60904 8.0215C6.8368 8.02249 6.06455 8.02479 5.29247 8.01887C5.20279 8.01821 5.09196 7.99666 5.02774 7.94022C4.70776 7.65905 4.35768 7.39778 4.09839 7.06379C3.75295 6.61891 3.4815 6.11365 3.17833 5.63422C3.19835 5.61859 3.21821 5.60313 3.23823 5.58766Z"
                      fill="#4B6C74"
                    />
                    <path
                      d="M17.6714 18.641C18.3394 17.7726 18.6684 16.7583 19.0666 15.7781C19.1685 15.5274 19.2889 15.2811 19.4299 15.0514C19.6121 14.7544 19.8144 14.7264 20.0764 14.9528C20.6161 15.4189 20.8744 16.0427 20.9772 16.7409C21.1249 17.7442 20.5408 18.678 19.6168 18.9199C19.0753 19.0617 18.5471 18.9686 18.0289 18.7891C17.9142 18.7494 17.8039 18.6963 17.6714 18.641Z"
                      fill="#4B6C74"
                    />
                    <path
                      d="M13.975 8.02216C14.5395 7.32901 14.9002 6.57153 15.2897 5.831C15.461 5.50507 15.6482 5.18391 15.8635 4.8876C16.0674 4.60676 16.2725 4.61367 16.5215 4.85223C17.2203 5.52202 17.4576 6.96688 17.0156 7.82555C16.945 7.9626 16.8686 8.02923 16.7028 8.02709C15.8686 8.0164 15.0341 8.02216 14.1995 8.02216C14.1403 8.02216 14.081 8.02216 13.975 8.02216Z"
                      fill="#4B6C74"
                    />
                    <path
                      d="M4.51671 4.04243C4.00999 3.41624 3.73517 2.19052 4.31508 1.38089C4.9001 0.563849 6.10219 0.282016 7.10329 0.733312C6.89894 1.25832 6.69618 1.78233 6.48943 2.3047C6.47326 2.34567 6.43514 2.38828 6.39671 2.40786C5.8341 2.69348 5.41146 3.15481 4.97281 3.5964C4.82307 3.74678 4.66949 3.8932 4.51671 4.04243Z"
                      fill="#4B6C74"
                    />
                  </svg>
                </div>
                <p>Snacks</p>
              </div>
              <div className="single-day-card">
                <div className="left-side">
                  <div>
                    <img src={require("../Assets/images/blog-img.png")} />
                    <div className="video-detail">
                      <h3> Jumping Jacks</h3>
                      <p>00:20</p>
                    </div>
                  </div>
                  {/* <button>Start</button> */}
                </div>
                <div className="right-side">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                  >
                    <path
                      d="M9.99191 2.1501C7.06273 2.1501 4.13359 2.15077 1.20441 2.1484C1.03342 2.14825 0.856936 2.1478 0.692521 2.10538C0.228746 1.98572 -0.0366775 1.53457 0.00410644 0.972528C0.042884 0.437868 0.357013 0.0746608 0.834382 0.0185348C0.950507 0.00490157 1.06812 0.000641184 1.18504 0.000604137C7.05899 -0.000210894 12.9329 -0.000359082 18.8069 0.00115984C18.9626 0.00119689 19.1218 0.00149326 19.2734 0.0339834C19.7386 0.133676 20.026 0.581758 19.9981 1.13942C19.9706 1.68998 19.6574 2.07078 19.1768 2.12954C19.0453 2.14562 18.9119 2.14925 18.7794 2.14928C15.8502 2.15036 12.9211 2.1501 9.99191 2.1501Z"
                      fill="#7D7D7D"
                    />
                    <path
                      d="M9.97544 8.5751C7.04626 8.5751 4.11712 8.57588 1.18795 8.5731C1.01737 8.57295 0.840609 8.56928 0.677439 8.52353C0.212765 8.39324 -0.0401712 7.94741 0.00573226 7.38C0.0486263 6.84949 0.365765 6.49518 0.850779 6.44172C0.967008 6.4289 1.08459 6.4256 1.20151 6.42557C7.06764 6.42482 12.9338 6.42457 18.7999 6.42668C18.9708 6.42675 19.1474 6.42783 19.3115 6.47128C19.7765 6.59446 20.0368 7.0418 19.9947 7.60699C19.955 8.14091 19.6408 8.50145 19.1604 8.55643C19.0288 8.57147 18.8955 8.57436 18.7629 8.57439C15.8338 8.57532 12.9046 8.5751 9.97544 8.5751Z"
                      fill="#7D7D7D"
                    />
                    <path
                      d="M10.0014 12.8499C12.9305 12.8499 15.8597 12.8494 18.7888 12.8513C18.9523 12.8514 19.1197 12.8509 19.2786 12.8858C19.7443 12.9882 20.0278 13.4367 19.9977 13.9952C19.9681 14.5456 19.6545 14.9221 19.1713 14.9803C19.0475 14.9952 18.9221 14.9993 18.7973 14.9994C12.9312 15.0002 7.06505 15.0005 1.19891 14.9984C1.02803 14.9983 0.851401 14.9969 0.687401 14.9533C0.223454 14.8301 -0.0379574 14.3803 0.00452154 13.8167C0.0447174 13.2834 0.35985 12.9225 0.839778 12.8682C0.971401 12.8533 1.10475 12.8506 1.23731 12.8506C4.1587 12.8497 7.08006 12.8499 10.0014 12.8499Z"
                      fill="#7D7D7D"
                    />
                  </svg>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="single-day-title">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                  >
                    <path
                      d="M7.0486 0.0436305C7.23535 0.236696 7.23192 0.316477 7.06871 0.579507C6.03567 2.24479 5.58267 4.0592 5.75851 6.00683C5.98578 8.52541 7.13169 10.5773 9.06714 12.1869C10.5381 13.4103 12.2419 14.1176 14.1373 14.3518C15.6041 14.533 17.0453 14.3903 18.4542 13.9386C18.4952 13.9255 18.5365 13.9128 18.5771 13.8987C18.7042 13.8546 18.852 13.8885 18.9335 13.9954C19.0247 14.1149 19.0213 14.2472 18.9284 14.364C18.5171 14.8802 18.1374 15.4278 17.6803 15.9002C16.177 17.4542 14.3457 18.4275 12.219 18.8199C7.34431 19.7194 2.32501 17.1998 0.536708 12.3742C0.261417 11.6313 0.103975 10.8497 0.0366327 10.0603L0.0353856 10.0466C0.016056 9.8175 0 9.4418 0 9.21181V8.89564C0 8.73826 0.00748243 8.58041 0.033515 8.42521C0.0356974 8.41275 0.0374121 8.40013 0.0386592 8.3875C0.0612624 8.15361 0.0734213 7.91785 0.11816 7.68708C0.711142 4.62328 2.37629 2.3439 5.03022 0.819008C5.59218 0.496142 6.21057 0.271133 6.80246 0C6.88523 0.0146474 6.96598 0.0289831 7.0486 0.0436305Z"
                      fill="#4B6C74"
                    />
                    <path
                      d="M17.2122 5.34069C17.2152 5.51942 17.1 5.62756 16.89 5.6433C16.3477 5.68381 15.9359 5.99484 15.7881 6.48412C15.7563 6.5893 15.7422 6.70103 15.731 6.81073C15.7109 7.00847 15.6091 7.12222 15.438 7.12502C15.2646 7.12783 15.1522 7.0147 15.136 6.82132C15.0769 6.11685 14.6638 5.70656 13.949 5.64252C13.7674 5.62631 13.6559 5.51646 13.6532 5.3516C13.6504 5.17786 13.763 5.06597 13.9566 5.04992C14.6601 4.99165 15.0718 4.57747 15.1362 3.86333C15.1525 3.68133 15.2618 3.57022 15.4269 3.56742C15.6004 3.56446 15.7132 3.67759 15.7291 3.87065C15.7874 4.57388 16.2026 4.98619 16.9159 5.04992C17.098 5.06629 17.2096 5.17567 17.2122 5.34069Z"
                      fill="#4B6C74"
                    />
                    <path
                      d="M11.1209 9.05334C10.9563 9.04913 10.8445 8.93787 10.8339 8.75431C10.8162 8.44812 10.72 8.17543 10.508 7.94917C10.286 7.71217 10.0076 7.5967 9.68771 7.57224C9.45966 7.55478 9.34555 7.45147 9.35007 7.26604C9.35443 7.08934 9.46542 6.99366 9.68569 6.97684C10.3312 6.92759 10.7817 6.47789 10.8314 5.8334C10.849 5.60528 10.9526 5.49122 11.1376 5.49573C11.3142 5.5001 11.411 5.61198 11.4274 5.83122C11.4755 6.47524 11.927 6.92744 12.5711 6.97684C12.7984 6.99429 12.9136 7.09853 12.9091 7.28272C12.9047 7.45926 12.7928 7.55572 12.5731 7.57224C11.9223 7.62101 11.4765 8.0729 11.4268 8.73421C11.4107 8.94395 11.3016 9.05801 11.1209 9.05334Z"
                      fill="#4B6C74"
                    />
                    <path
                      d="M13.9525 12.7618C13.7745 12.7638 13.6676 12.6488 13.6515 12.4378C13.6104 11.896 13.2975 11.4826 12.809 11.3377C12.6978 11.3047 12.5795 11.2933 12.4635 11.2802C12.2833 11.2596 12.1714 11.1517 12.1695 10.9866C12.1677 10.8204 12.2782 10.7048 12.4561 10.687C13.1934 10.6138 13.5861 10.2236 13.6526 9.49837C13.6691 9.3173 13.7797 9.20604 13.9449 9.20417C14.1109 9.20215 14.2272 9.3131 14.245 9.49058C14.3189 10.2286 14.7089 10.6209 15.4339 10.6868C15.6147 10.7034 15.7263 10.814 15.7284 10.979C15.7304 11.145 15.6191 11.2612 15.4415 11.279C14.7028 11.3528 14.3117 11.7413 14.2451 12.4676C14.2286 12.648 14.1175 12.7599 13.9525 12.7618Z"
                      fill="#4B6C74"
                    />
                  </svg>
                </div>
                <p>Dinner</p>
              </div>
              <div className="single-day-card">
                <div className="left-side">
                  <div>
                    <img src={require("../Assets/images/blog-img.png")} />
                    <div className="video-detail">
                      <h3> Jumping Jacks</h3>
                      <p>00:20</p>
                    </div>
                  </div>
                  {/* <button>Start</button> */}
                </div>
                <div className="right-side">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                  >
                    <path
                      d="M9.99191 2.1501C7.06273 2.1501 4.13359 2.15077 1.20441 2.1484C1.03342 2.14825 0.856936 2.1478 0.692521 2.10538C0.228746 1.98572 -0.0366775 1.53457 0.00410644 0.972528C0.042884 0.437868 0.357013 0.0746608 0.834382 0.0185348C0.950507 0.00490157 1.06812 0.000641184 1.18504 0.000604137C7.05899 -0.000210894 12.9329 -0.000359082 18.8069 0.00115984C18.9626 0.00119689 19.1218 0.00149326 19.2734 0.0339834C19.7386 0.133676 20.026 0.581758 19.9981 1.13942C19.9706 1.68998 19.6574 2.07078 19.1768 2.12954C19.0453 2.14562 18.9119 2.14925 18.7794 2.14928C15.8502 2.15036 12.9211 2.1501 9.99191 2.1501Z"
                      fill="#7D7D7D"
                    />
                    <path
                      d="M9.97544 8.5751C7.04626 8.5751 4.11712 8.57588 1.18795 8.5731C1.01737 8.57295 0.840609 8.56928 0.677439 8.52353C0.212765 8.39324 -0.0401712 7.94741 0.00573226 7.38C0.0486263 6.84949 0.365765 6.49518 0.850779 6.44172C0.967008 6.4289 1.08459 6.4256 1.20151 6.42557C7.06764 6.42482 12.9338 6.42457 18.7999 6.42668C18.9708 6.42675 19.1474 6.42783 19.3115 6.47128C19.7765 6.59446 20.0368 7.0418 19.9947 7.60699C19.955 8.14091 19.6408 8.50145 19.1604 8.55643C19.0288 8.57147 18.8955 8.57436 18.7629 8.57439C15.8338 8.57532 12.9046 8.5751 9.97544 8.5751Z"
                      fill="#7D7D7D"
                    />
                    <path
                      d="M10.0014 12.8499C12.9305 12.8499 15.8597 12.8494 18.7888 12.8513C18.9523 12.8514 19.1197 12.8509 19.2786 12.8858C19.7443 12.9882 20.0278 13.4367 19.9977 13.9952C19.9681 14.5456 19.6545 14.9221 19.1713 14.9803C19.0475 14.9952 18.9221 14.9993 18.7973 14.9994C12.9312 15.0002 7.06505 15.0005 1.19891 14.9984C1.02803 14.9983 0.851401 14.9969 0.687401 14.9533C0.223454 14.8301 -0.0379574 14.3803 0.00452154 13.8167C0.0447174 13.2834 0.35985 12.9225 0.839778 12.8682C0.971401 12.8533 1.10475 12.8506 1.23731 12.8506C4.1587 12.8497 7.08006 12.8499 10.0014 12.8499Z"
                      fill="#7D7D7D"
                    />
                  </svg>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}
