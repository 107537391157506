import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function MainGoialMale() {
  return (
    <div>
      <Container fluid>
        <div className="main-form">
          <Row>
            <Col lg={7} className="px-0">
              <div className="left-img">
                <img src={require("../Assets/images/leftimg.png")} />
              </div>
            </Col>
            <Col lg={5} className="form-right">
              <div className="logo">
                <img src={require("../Assets/images/LOGO.png")} />
              </div>
              <div className="cmn-form-wrap">
                <div className="cmn-form">
                  <h2 className="heading-30">
                  What are your main
                    <br />
                    main goals?
                  </h2>
                  <div className="form-outer">
                    <div className="gender-toggle flex-column align-items-center">
                      <div className="toggle-btn mb-2">
                        <input type="radio" name="radio" />
                        <button type="button">Lose Weight</button>
                      </div>
                      <div className="toggle-btn mb-2">
                        <input type="radio" name="radio" />
                        <button type="button">Build Muscle</button>
                      </div>
                      <div className="toggle-btn mb-2">
                        <input type="radio" name="radio" />
                        <button type="button">Get Toned</button>
                      </div>
                      <div className="toggle-btn mb-2">
                        <input type="radio" name="radio" />
                        <button type="button">Healthy Lifestyle</button>
                      </div>
                    </div>
                  </div>
                  <Link to="/" className="cmn-green-btn mt-5 mb-4">
                    Next
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
