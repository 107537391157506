// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCbuSZLh5k9m9A50EEDSmJT5Tt_Modcaqw",
  authDomain: "leggera-dd0d6.firebaseapp.com",
  projectId: "leggera-dd0d6",
  storageBucket: "leggera-dd0d6.appspot.com",
  messagingSenderId: "979708269220",
  appId: "1:979708269220:web:de283588252ade81658c31",
  measurementId: "G-VQMBGM2VNL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider };