import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = () => {
  const data = {
    labels: [
      "Jan 1",
      "Jan 2",
      "Jan 3",
      "Jan 4",
      "Jan 5",
      "Jan 6",
      "Jan 7",
      "Jan 6",
      "Jan 8",
      "Jan 9",
      "Jan 10",
      "Jan 11",
      "Jan 12",
      "Jan 13",
      "Jan 14",
      "Jan 15",
      "Jan 16",
    ], // Days on X-axis
    datasets: [
      {
        label: "Weight (kg)",
        data: [0, 20, 25, 15, 10, 20, 17, 20, 30, 50, 55, 57, 20, 30, 40, 20], // Weights on Y-axis
        borderColor: "#4B6C74",
        backgroundColor: "rgba(75,192,192,0.2)",
        tension: 0.2,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Days",
        },
        grid: {
          display: false, // Hide X-axis gridlines
        },
      },
      y: {
        title: {
          display: true,
          text: "Weight (kg)",
        },
        grid: {
          display: true, // Hide Y-axis gridlines
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default LineChart;
