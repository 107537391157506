// src/components/GoogleLogin.js
import React, { useEffect } from "react";
import { signInWithPopup } from "firebase/auth";

import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { auth, googleProvider } from "../services/Firebase";
import useMutationHook from "../myHooks/mutationHook";
import { socialLogin } from "../redux/Actions/userActions";
import { userIdforGetDetails } from "../redux/Reducer/userDetailsSlics";
const GoogleLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // ============================socilaLogin login====================================================

  const { mutate, isPending, isError, isSuccess, data } =
    useMutationHook(socialLogin);

  useEffect(() => {
    if (isSuccess) {
      console.log(data, "data");
      if (data?.success) {
        sessionStorage.setItem("token", data?.data?.token);
        dispatch(userIdforGetDetails(data?.data?.data?._id));
        if (data?.data?.data?.isProfileCompleted == 0) {
          window.location.href = "/gender";
        } else {
          window.location.href = "/";
        }
      }
    }
  }, [isSuccess]);

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log("hii");
    try {
      console.log("first");
      const result = await signInWithPopup(auth, googleProvider);
      console.log("User Info: ", result?.user);
      if (result?.user) {
        mutate({
          socialId: result?.user?.uid,
          full_name: result?.user?.displayName,
          login_type: 2,
          device_token: result?.user?.accessToken,
          device_type: 2,
          email: result?.user?.email,
          name: result?.user?.displayName,
        });
      }
    } catch (error) {
      console.error("Error during login: ", error.message);
    }
  };

  return (
    <div>
      <div
        onClick={handleLogin}
        style={{ cursor: "pointer" }}
        className="goggle-bnt"
      >
        {isPending ? (
          <div className="loader"></div>
        ) : (
          <>
            <img src={require("../Assets/images/google.png")} />
            <p className="mb-0">Continue with Google</p>
          </>
        )}
      </div>
    </div>
  );
};

export default GoogleLogin;
