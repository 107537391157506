import React from "react";
import Layout from "../Components/Layout";
import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

export default function SingleExcersiseDetailDiet() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showtwo, setShowtwo] = useState(false);

  const handleClosetwo = () => setShowtwo(false);
  const handleShowtwo = () => setShowtwo(true);
  return (
    <Layout>
      <section className="body-challenge-banner">
        <Container className="mt-5">
          <Row>
            <Col lg={7}>
              <div className="single-excersise">
                <div className="single-excersise-video">
                  <img src={require("../Assets/images/blog-img.png")} />
                </div>
                <div className="single-excersise-details">
                  <div className="single-excersise-title">
                    <h2>Green Goddess Wrap</h2>
                  </div>
                  <div className="single-excersise-desc">
                    <p>
                      This recipe features a vibrant and refreshing salad made
                      with a medley of mixed greens, accompanied by a flavorful
                      sun-dried tomato dressing.
                    </p>
                    <h5>Ingredients</h5>

                    <ul className="ps-3">
                      <li>1/4 cup Sun-dried tomatoes</li>
                      <li>2 tsp Soymilk</li>
                      <li>5/8 tsp Rosemary</li>
                      <li>3/4 tsp Thyme</li>
                      <li>1/4 medium whole Tomatoes</li>
                      <li>1/4 Fruit without seeds Lemons</li>
                    </ul>
                    <h5>Instructions</h5>
                    <p>
                      1. Soak the sun-dried tomatoes in the soy milk for an
                      hour.
                    </p>
                    <p>
                      2. Chop the fresh herbs, tomato, and Onions and toss them
                      with the salad greens. Add additional veggies as desired.
                    </p>
                    <p>
                      3. Juice the lemon and combine in a high-powered blender
                      with the sun dried tomato mixture and garlic until smooth.
                      Pour over salad and toss together well. Top with brazil
                      nuts and enjoy!
                    </p>
                  </div>

                  <div className="single-excersise-desc">
                    <h5>Cooking Notes</h5>
                    <p>
                      1. Ensure the freshness of your mixed greens. Look for
                      crisp, vibrant leaves with no signs of writing or
                      browning.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={5}>
              <div className="nutrition-card mb-4">
                <h2>Nutrition Facts</h2>
                <div className="nutrition-facts-list">
                  <ul>
                    <li>Calories</li>
                    <li>
                      <b>494</b>
                    </li>
                  </ul>
                  <ul>
                    <li>Carbs</li>
                    <li>
                      <b>494</b>
                    </li>
                  </ul>
                  <ul>
                    <li>Fat</li>
                    <li>
                      <b>494</b>
                    </li>
                  </ul>
                  <ul>
                    <li>Protein</li>
                    <li>
                      <b>494</b>
                    </li>
                  </ul>
                  <ul>
                    <li>Fiber</li>
                    <li>
                      <b>494</b>
                    </li>
                  </ul>
                  <ul>
                    <li>Net Carbs</li>
                    <li>
                      <b>494</b>
                    </li>
                  </ul>
                  <ul>
                    <li>Sodium</li>
                    <li>
                      <b>494</b>
                    </li>
                  </ul>
                  <ul className="border-0 m-0 p-0">
                    <li>Cholesterol</li>
                    <li>
                      <b>494</b>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="nutrition-card">
                <h2>Other Recipes</h2>
                <ul className="recipes-list">
                  <li>
                    <div className="recipes-card">
                      <img src={require("../Assets/images/blog-img.png")} />
                      <div>
                        <h2>Green Goddess Wrap</h2>
                        <p>350 calories</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="recipes-card">
                      <img src={require("../Assets/images/blog-img.png")} />
                      <div>
                        <h2>Green Goddess Wrap</h2>
                        <p>350 calories</p>
                      </div>
                    </div>
                  </li>
                  <li className="mb-0">
                    <div className="recipes-card">
                      <img src={require("../Assets/images/blog-img.png")} />
                      <div>
                        <h2>Green Goddess Wrap</h2>
                        <p>350 calories</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="our-blogs pt-0">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="commn-blog-title">
                <h2>Our Recent Blogs</h2>
              </div>
            </Col>
            <Col lg={4}>
              <div className="commn-blog-box">
                <img src={require("../Assets/images/blog-img.png")} />
                <div className="commn-blog-details">
                  <div className="commn-blog-time">
                    <div>Anthem</div>
                    <p>24 January 2024</p>
                  </div>
                  <h3>Veg Salad with Tangy Twist</h3>
                  <p>
                    Lorem Ipsum is simply dummy text for printing and
                    typesetting industry. Lorem ipsum has been industry’s
                    standard dummy text.
                  </p>
                  <Link to="">Read More</Link>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="commn-blog-box">
                <img src={require("../Assets/images/blog-img.png")} />
                <div className="commn-blog-details">
                  <div className="commn-blog-time">
                    <div>Anthem</div>
                    <p>24 January 2024</p>
                  </div>
                  <h3>Veg Salad with Tangy Twist</h3>
                  <p>
                    Lorem Ipsum is simply dummy text for printing and
                    typesetting industry. Lorem ipsum has been industry’s
                    standard dummy text.
                  </p>
                  <Link to="">Read More</Link>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="commn-blog-box">
                <img src={require("../Assets/images/blog-img.png")} />
                <div className="commn-blog-details">
                  <div className="commn-blog-time">
                    <div>Anthem</div>
                    <p>24 January 2024</p>
                  </div>
                  <h3>Veg Salad with Tangy Twist</h3>
                  <p>
                    Lorem Ipsum is simply dummy text for printing and
                    typesetting industry. Lorem ipsum has been industry’s
                    standard dummy text.
                  </p>
                  <Link to="">Read More</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="pause-video-modal">
            <h2>Pause</h2>
            <p>Inclined Push Ups</p>
            <div className="video-pause-actions">
              <button className="btn-outline">Restart Exercise</button>
              <button className="btn-theme">Resume Exercise</button>
              <button className="btn-danger">Quit Exercise</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showtwo} onHide={handleClosetwo}>
        <Modal.Header closeButton className="border-0">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="pause-video-modal">
            <h2>
              How do you feel about
              <br /> today’s workout
            </h2>
            <div className="today-workout-options">
              <div className="form-outer">
                <div className="gender-toggle flex-column align-items-center">
                  <div className="toggle-btn mb-2">
                    <input type="radio" name="radio" />
                    <button type="button">Too Easy</button>
                  </div>
                  <div className="toggle-btn mb-2">
                    <input type="radio" name="radio" />
                    <button type="button"> A Little Easy</button>
                  </div>
                  <div className="toggle-btn mb-2">
                    <input type="radio" name="radio" />
                    <button type="button">Just Right</button>
                  </div>
                  <div className="toggle-btn mb-2">
                    <input type="radio" name="radio" />
                    <button type="button">A Little Hard</button>
                  </div>
                  <div className="toggle-btn mb-2">
                    <input type="radio" name="radio" />
                    <button type="button">Too Hard</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="video-pause-actions mt-3">
              <button className="btn-theme">Submit</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}
