import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import {
  resendOTP,
  resetPassword,
  verifyOTP,
} from "../redux/Actions/userActions";
import useMutationHook from "../myHooks/mutationHook";
import { useDispatch } from "react-redux";
import { userIdforGetDetails } from "../redux/Reducer/userDetailsSlics";

export default function Otp() {
  const location = useLocation();
  console.log(location, "location");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // ===============================================REACT STATE======================================
  const [otp, setOtp] = useState("");
  const [show, setShow] = useState(false);
  const [timer, setTimer] = useState(60);
  const [userId, setUserId] = useState(null);
  const [purpose, setPurpose] = useState(null);

  //  ==========================================open password change otp==============================

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //===========================================start timer===========================================

  useEffect(() => {
    let timeInterval = null;

    if (location?.state) {
      setUserId(location?.state?.userId);
      if (location?.state?.purpose) {
        setPurpose(location?.state?.purpose);
      }
    }

    timeInterval = setInterval(() => {
      if (timer === 0) {
        clearInterval(timeInterval); // Clear the interval when timer reaches 0
      } else {
        setTimer((old) => old - 1);
      }
    }, 1000);

    return () => {
      if (timeInterval) {
        clearInterval(timeInterval); // Clear the interval when the component unmounts
      }
    };
  }, [location, timer]);

  console.log(purpose, "pur");

  // ===========================================post otp===========================================

  const { mutate, isPending, isError, isSuccess, data } =
    useMutationHook(verifyOTP);

  useEffect(() => {
    if (isSuccess) {
      console.log(data, "data");
      if (data?.success) {
        if (purpose == "forgetPassword") {
          navigate(`/reset-password/${data?.data?.userData?._id}`, {
            
          });
        } else {
          sessionStorage.setItem("token", data?.data?.token);

          dispatch(userIdforGetDetails(data?.data?.userData?._id));

          window.location.href = "/gender";
        }
        // purpose == "forgetPassword"
        //   ? navigate("/reset-password")
        //   : (sessionStorage.setItem("token", data?.data?.token)
        // navigate("/gender")
        // dispatch(userIdforGetDetails(data?.data?.userData?._id)))
      }
    }
  }, [isSuccess]);

  // =============================================resend otp========================================

  const {
    mutate: resendmutate,
    isPending: resendisPending,
    isError: resendisError,
    isSuccess: resendisSuccess,
    data: resenddata,
  } = useMutationHook(resendOTP);

  useEffect(() => {
    if (resendisSuccess) {
      console.log(resenddata, "resenddata");
      if (resenddata?.success) {
        setTimer(60);
      }
    }
  }, [resendisSuccess]);

  console.log(userId);

  // ============================================SUBMIT===============================================

  const handlesave = () => {
    let isValid = true;

    if (timer == 0) {
      resendmutate({ userId: userId });
    } else {
      if (otp.length !== 4) {
        toast.error("Please enter a 4-digit OTP");
        isValid = false;
      }

      if (isValid) {
        console.log(otp);
        mutate({
          userId: userId,
          otp: otp,
        });
      }
    }
  };

  return (
    <div>
      <Container fluid>
        <div className="main-form">
          <Row>
            <Col lg={7} className="px-0">
              <div className="left-img">
                <img src={require("../Assets/images/leftimg.png")} />
              </div>
            </Col>
            <Col lg={5} className="form-right">
              <div className="logo">
                <img src={require("../Assets/images/LOGO.png")} />
              </div>
              <div className="cmn-form-wrap">
                <div className="cmn-form">
                  <h2 className="heading-30 mb-2">OTP</h2>
                  <p className="sub-16 width-set">
                    Enter the verification code we just sent on your email
                    address.
                  </p>
                  <div className="otp-sec">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => (
                        <input
                          {...props}
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            color: "black",
                          }}
                        />
                      )}
                    />
                  </div>
                  {timer > 0 && (
                    <h6 className="text-center mt-5">Resend Code: {timer}</h6>
                  )}

                  {timer == 0 ? (
                    <Link
                      to="#"
                      className="cmn-green-btn mt-5 mb-4"
                      // onClick={handleShow}
                      onClick={handlesave}
                    >
                      {resendisError ? (
                        <div className="loader"></div>
                      ) : (
                        "Resend"
                      )}
                    </Link>
                  ) : (
                    <Link
                      to="#"
                      className="cmn-green-btn mt-5 mb-4"
                      // onClick={handleShow}
                      onClick={handlesave}
                    >
                      {isPending ? <div className="loader"></div> : "Verify"}
                    </Link>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        className="cmn-modal"
      >
        <Modal.Body>
          <div className="modal-inner">
            <img src={require("../Assets/images/tickmark.png")} />
            <h3>Password Changed</h3>
            <p>Your Password has been changed successfully!</p>
            <div className="d-flex justify-content-center">
              <Link to="/">Back to login</Link>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
}
