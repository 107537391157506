import React, { useEffect } from "react";
import Layout from "../Components/Layout";
import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

export default function BlogsListing() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showtwo, setShowtwo] = useState(false);

  const handleClosetwo = () => setShowtwo(false);
  const handleShowtwo = () => setShowtwo(true);
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <section className="body-challenge-banner our-blogs pt-0">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="commn-blog-title">
                <h2>Recommended</h2>
              </div>
            </Col>
            <Col lg={12} className="mb-5">
              <Row className="align-items-center">
                <Col lg={6}>
                  <div className="top-blog-img">
                    <img src={require("../Assets/images/blog-img.png")} />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="top-blog-text">
                    <div>
                      <p>Dope Anthem</p>
                      <p>24 January 2024</p>
                    </div>
                    <h3>Veg Salad with Tangy Twist</h3>
                    <p>
                      Lorem Ipsum is simply dummy text for printing and
                      typesetting industry. Lorem ipsum has been industry’s
                      standard dummy text ever since 1500s, when an unknown
                      printer took galley of it and scrambled it to make a
                      specimen copy. Lorem Ipsum is simply dummy text for
                      printing and typesetting industry.
                    </p>
                    <Link to="/single-blogs-detail">Read More</Link>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={12}>
              <div className="commn-blog-title">
                <h2>More Blogs</h2>
              </div>
            </Col>

            <Col lg={4}>
              <div className="commn-blog-box">
                <img src={require("../Assets/images/blog-img.png")} />
                <div className="commn-blog-details">
                  <div className="commn-blog-time">
                    <div>Anthem</div>
                    <p>24 January 2024</p>
                  </div>
                  <h3>Veg Salad with Tangy Twist</h3>
                  <p>
                    Lorem Ipsum is simply dummy text for printing and
                    typesetting industry. Lorem ipsum has been industry’s
                    standard dummy text.
                  </p>
                  <Link to="/single-blogs-detail">Read More</Link>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="commn-blog-box">
                <img src={require("../Assets/images/blog-img.png")} />
                <div className="commn-blog-details">
                  <div className="commn-blog-time">
                    <div>Anthem</div>
                    <p>24 January 2024</p>
                  </div>
                  <h3>Veg Salad with Tangy Twist</h3>
                  <p>
                    Lorem Ipsum is simply dummy text for printing and
                    typesetting industry. Lorem ipsum has been industry’s
                    standard dummy text.
                  </p>
                  <Link to="/single-blogs-detail">Read More</Link>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="commn-blog-box">
                <img src={require("../Assets/images/blog-img.png")} />
                <div className="commn-blog-details">
                  <div className="commn-blog-time">
                    <div>Anthem</div>
                    <p>24 January 2024</p>
                  </div>
                  <h3>Veg Salad with Tangy Twist</h3>
                  <p>
                    Lorem Ipsum is simply dummy text for printing and
                    typesetting industry. Lorem ipsum has been industry’s
                    standard dummy text.
                  </p>
                  <Link to="/single-blogs-detail">Read More</Link>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="commn-blog-box">
                <img src={require("../Assets/images/blog-img.png")} />
                <div className="commn-blog-details">
                  <div className="commn-blog-time">
                    <div>Anthem</div>
                    <p>24 January 2024</p>
                  </div>
                  <h3>Veg Salad with Tangy Twist</h3>
                  <p>
                    Lorem Ipsum is simply dummy text for printing and
                    typesetting industry. Lorem ipsum has been industry’s
                    standard dummy text.
                  </p>
                  <Link to="/single-blogs-detail">Read More</Link>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="commn-blog-box">
                <img src={require("../Assets/images/blog-img.png")} />
                <div className="commn-blog-details">
                  <div className="commn-blog-time">
                    <div>Anthem</div>
                    <p>24 January 2024</p>
                  </div>
                  <h3>Veg Salad with Tangy Twist</h3>
                  <p>
                    Lorem Ipsum is simply dummy text for printing and
                    typesetting industry. Lorem ipsum has been industry’s
                    standard dummy text.
                  </p>
                  <Link to="/single-blogs-detail">Read More</Link>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="commn-blog-box">
                <img src={require("../Assets/images/blog-img.png")} />
                <div className="commn-blog-details">
                  <div className="commn-blog-time">
                    <div>Anthem</div>
                    <p>24 January 2024</p>
                  </div>
                  <h3>Veg Salad with Tangy Twist</h3>
                  <p>
                    Lorem Ipsum is simply dummy text for printing and
                    typesetting industry. Lorem ipsum has been industry’s
                    standard dummy text.
                  </p>
                  <Link to="/single-blogs-detail">Read More</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="pause-video-modal">
            <h2>Pause</h2>
            <p>Inclined Push Ups</p>
            <div className="video-pause-actions">
              <button className="btn-outline">Restart Exercise</button>
              <button className="btn-theme">Resume Exercise</button>
              <button className="btn-danger">Quit Exercise</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showtwo} onHide={handleClosetwo}>
        <Modal.Header closeButton className="border-0">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="pause-video-modal">
            <h2>
              How do you feel about
              <br /> today’s workout
            </h2>
            <div className="today-workout-options">
              <div className="form-outer">
                <div className="gender-toggle flex-column align-items-center">
                  <div className="toggle-btn mb-2">
                    <input type="radio" name="radio" />
                    <button type="button">Too Easy</button>
                  </div>
                  <div className="toggle-btn mb-2">
                    <input type="radio" name="radio" />
                    <button type="button"> A Little Easy</button>
                  </div>
                  <div className="toggle-btn mb-2">
                    <input type="radio" name="radio" />
                    <button type="button">Just Right</button>
                  </div>
                  <div className="toggle-btn mb-2">
                    <input type="radio" name="radio" />
                    <button type="button">A Little Hard</button>
                  </div>
                  <div className="toggle-btn mb-2">
                    <input type="radio" name="radio" />
                    <button type="button">Too Hard</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="video-pause-actions mt-3">
              <button className="btn-theme">Submit</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}
