import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { registration } from "../redux/Actions/userActions";
import useMutationHook from "../myHooks/mutationHook";
import GoogleLogin from "../Components/GoogleLogin";

export default function CreateAccount() {
  const navigate = useNavigate();

  // ====================================react state=============================================

  const [registrationData, setRegistrationData] = useState({
    email: "",
    password: "",
    term: false,
  });

  console.log(registrationData?.term);

  //===================================handlechange===============================================

  const handlechange = (e) => {
    setRegistrationData((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  const handlechecked = (e) => {
    setRegistrationData((old) => ({ ...old, term: e.target.checked }));
  };

  // ============================custom hook====================================================

  const { mutate, isPending, isError, isSuccess, data } =
    useMutationHook(registration);

  useEffect(() => {
    if (isSuccess) {
      console.log(data, "data");
      if (data?.success) {
        navigate("/otp", {
          state: {
            userId: data?.data?._id,
          },
        });
      }
    }
  }, [isSuccess]);

  console.log(isSuccess, "success");

  //======================================handlesubmit============================================

  const handlesubmit = () => {

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!registrationData?.email) {
      toast.error("Please enter your email");
    } 
    else if (!emailRegex.test(registrationData?.email)) {
      toast.error("Please Enter a Valid Email");
      
    } else if (!registrationData?.password) {
      toast.error("Please enter your password");
    } else if (registrationData?.term == false) {
      toast.error("Please accept terms and conditions");
    } else {
      console.log(registrationData);
      mutate(registrationData);
    }
  };

  return (
    <div>
      <Container fluid>
        <div className="main-form">
          <Row>
            <Col lg={7} className="px-0">
              <div className="left-img">
                <img src={require("../Assets/images/leftimg.png")} />
              </div>
            </Col>
            <Col lg={5} className="form-right">
              <div className="logo">
                <img src={require("../Assets/images/LOGO.png")} />
              </div>
              <div className="cmn-form-wrap">
                <div className="cmn-form">
                  <h2 className="heading-30">
                    Create Account to <br />
                    Get Started
                  </h2>

                  <div className="cmn-form-fields">
                    <Form.Group className="mb-3 form-inner">
                      <label>Email address:</label>
                      <Form.Control
                        name="email"
                        value={registrationData?.email}
                        onChange={handlechange}
                        type="text"
                        placeholder="Enter here"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 form-inner">
                      <label>Password:</label>
                      <Form.Control
                        name="password"
                        value={registrationData?.password}
                        onChange={handlechange}
                        type="password"
                        placeholder="Enter here"
                      />
                    </Form.Group>
                    <div className="rem-for">
                      <div className="form-check">
                        <input
                          name="term"
                          value={registrationData?.term}
                          onChange={handlechecked}
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          I accept terms and conditions
                        </label>
                      </div>
                    </div>
                  </div>
                  <Link
                    // to="/gender"
                    onClick={handlesubmit}
                    className="cmn-green-btn mt-5 mb-4"
                  >
                    {isPending ? <div className="loader"></div> : "Sign up"}
                  </Link>
                  <GoogleLogin />
                  <div className="form-bottom">
                    <h2>
                      Don’t have an account? <Link to="/login">Login Now</Link>
                    </h2>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
