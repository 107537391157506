import React from "react";
import Layout from "../Components/Layout";
import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

export default function SingleExcersiseDetail() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showtwo, setShowtwo] = useState(false);

  const handleClosetwo = () => setShowtwo(false);
  const handleShowtwo = () => setShowtwo(true);
  return (
    <Layout>
      <section className="body-challenge-banner">
        <Container className="mt-5">
          <div className="single-excersise">
            <div className="single-excersise-video">
              <img src={require("../Assets/images/blog-img.png")} />
            </div>
            <div className="single-excersise-details">
              <div className="single-excersise-title">
                <h2>Jumping Jacks</h2>
                <Link to="/exercise-completed">Start</Link>
              </div>
              <div className="single-excersise-action">
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M17.793 19.8635C18.2515 20.029 18.7685 20 19.1825 19.7502C19.5091 19.553 19.7323 19.2536 19.8505 18.8614C19.9339 18.5842 19.993 18.2893 19.9935 18.0023C20.0018 12.6621 20.0035 7.32183 19.9905 1.98162C19.9896 1.62721 19.9031 1.25851 19.7796 0.92192C19.5484 0.292097 18.9128 -0.0332651 18.2208 0.0512408C17.7096 0.113648 17.2813 0.345765 16.8688 0.615267C13.0736 3.09505 9.27834 5.57469 5.48521 8.05736C5.2837 8.18926 5.08933 8.3357 4.91248 8.49614C4.23158 9.11377 4.0215 9.95587 4.35293 10.7326C4.57115 11.2441 4.97203 11.6085 5.4417 11.9155C9.06781 14.2856 12.6933 16.6567 16.3179 19.0289C16.7918 19.339 17.2535 19.6688 17.793 19.8635Z"
                      fill="#818181"
                    />
                    <path
                      d="M1.91432 19.9163C2.16517 19.8385 2.40203 19.7326 2.54272 19.5068C2.58144 19.4447 2.61837 19.3802 2.65138 19.3135C2.74185 19.1307 2.78808 18.9303 2.78829 18.7283C2.78829 18.7277 2.78829 18.7272 2.78829 18.7266C2.7884 12.899 2.78854 7.07144 2.78822 1.24386C2.78819 0.61088 2.47495 0.165056 1.8677 0.062938C1.36977 -0.0208035 0.843168 -0.0782927 0.403818 0.280334C0.152231 0.4857 0.0258083 0.750582 0.00447655 1.06259C0.000490189 1.12077 0.00189018 1.17936 0.00189018 1.23775C0.00178528 7.07181 0.00276375 12.9059 0 18.7399C-0.000209808 19.2187 0.164017 19.6031 0.620886 19.8407C0.658377 19.8602 0.697756 19.8764 0.738043 19.891C1.11455 20.0281 1.5311 20.0351 1.91432 19.9163Z"
                      fill="#818181"
                    />
                  </svg>
                  Previous
                </p>
                <div className="video-pause-action">
                  <h3>00:17</h3>
                  <div className="pause-action-btns">
                    <button className="btn-fill" onClick={handleShowtwo}>
                      Pause
                    </button>
                    <button className="btn-outline">Skip</button>
                  </div>
                </div>
                <p>
                  Next
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M2.20696 19.8635C1.74855 20.029 1.23146 20 0.817532 19.7502C0.490896 19.553 0.267672 19.2536 0.149537 18.8614C0.0660948 18.5842 0.00695762 18.2893 0.00650299 18.0023C-0.00178531 12.6621 -0.00353389 7.32183 0.00951055 1.98162C0.0103848 1.62721 0.0969048 1.25851 0.220425 0.92192C0.451553 0.292097 1.0872 -0.0332651 1.77922 0.0512408C2.29043 0.113648 2.71866 0.345765 3.13115 0.615267C6.92639 3.09505 10.7217 5.57469 14.5148 8.05736C14.7163 8.18926 14.9107 8.3357 15.0875 8.49614C15.7684 9.11377 15.9785 9.95587 15.6471 10.7326C15.4288 11.2441 15.028 11.6085 14.5583 11.9155C10.9322 14.2856 7.30674 16.6567 3.68206 19.0289C3.20823 19.339 2.74647 19.6688 2.20696 19.8635Z"
                      fill="#818181"
                    />
                    <path
                      d="M18.0857 19.9163C17.8348 19.8385 17.598 19.7326 17.4573 19.5068C17.4186 19.4447 17.3816 19.3802 17.3486 19.3135C17.2581 19.1307 17.2119 18.9303 17.2117 18.7283C17.2117 18.7277 17.2117 18.7272 17.2117 18.7266C17.2116 12.899 17.2115 7.07144 17.2118 1.24386C17.2118 0.61088 17.5251 0.165056 18.1323 0.062938C18.6302 -0.0208035 19.1568 -0.0782927 19.5962 0.280334C19.8478 0.4857 19.9742 0.750582 19.9955 1.06259C19.9995 1.12077 19.9981 1.17936 19.9981 1.23775C19.9982 7.07181 19.9972 12.9059 20 18.7399C20.0002 19.2187 19.836 19.6031 19.3791 19.8407C19.3416 19.8602 19.3022 19.8764 19.262 19.891C18.8855 20.0281 18.4689 20.0351 18.0857 19.9163Z"
                      fill="#818181"
                    />
                  </svg>
                </p>
              </div>
              <div className="single-excersise-desc">
                <h5>Instructions</h5>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries,
                </p>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries,
                </p>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries,
                </p>
              </div>

              <div className="single-excersise-desc">
                <h5>Focus Area</h5>
                <ul className="focus-area-list">
                  <li>Shoulders</li>
                  <li>Quadriceps</li>
                  <li>Abductors</li>
                  <li>Glutes</li>
                  <li>Calves</li>
                  <li>Chest</li>
                </ul>
              </div>

              <div className="single-excersise-desc">
                <h5>Benefit Tips</h5>
                <ul className="ps-3">
                  <li>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever.
                  </li>
                  <li>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever.
                  </li>
                  <li>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever.
                  </li>
                  <li>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever.
                  </li>
                  <li>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="pause-video-modal">
            <h2>Pause</h2>
            <p>Inclined Push Ups</p>
            <div className="video-pause-actions">
              <button className="btn-outline">Restart Exercise</button>
              <button className="btn-theme">Resume Exercise</button>
              <button className="btn-danger">Quit Exercise</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showtwo} onHide={handleClosetwo}>
        <Modal.Header closeButton className="border-0">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="pause-video-modal">
            <h2>
              How do you feel about
              <br /> today’s workout
            </h2>
            <div className="today-workout-options">
              <div className="form-outer">
                <div className="gender-toggle flex-column align-items-center">
                  <div className="toggle-btn mb-2">
                    <input type="radio" name="radio" />
                    <button type="button">Too Easy</button>
                  </div>
                  <div className="toggle-btn mb-2">
                    <input type="radio" name="radio" />
                    <button type="button"> A Little Easy</button>
                  </div>
                  <div className="toggle-btn mb-2">
                    <input type="radio" name="radio" />
                    <button type="button">Just Right</button>
                  </div>
                  <div className="toggle-btn mb-2">
                    <input type="radio" name="radio" />
                    <button type="button">A Little Hard</button>
                  </div>
                  <div className="toggle-btn mb-2">
                    <input type="radio" name="radio" />
                    <button type="button">Too Hard</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="video-pause-actions mt-3">
              <button className="btn-theme">Submit</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}
