import React, { useEffect, useState } from "react";
import { Col, Container, Dropdown, Modal, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import useQueryHook from "../myHooks/queryHook";
import {
  changePassword,
  deleteUser,
  editProfile,
  getUserById,
  logOut,
} from "../redux/Actions/userActions";
import useMutationHook from "../myHooks/mutationHook";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as yup from "yup";

export default function Header() {
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  // =============================================================================================================
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [file, setFile] = useState(null);

  const [showsubs, setShowsubs] = useState(false);

  const handleClosesubs = () => setShowsubs(false);
  const handleShowsubs = () => setShowsubs(true);

  const [showpass, setShowpass] = useState(false);

  const handleClosepass = () => setShowpass(false);
  const handleShowpass = () => setShowpass(true);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
  };

  // =====================================================react state========================================

  const [confirmpassword, setconfirmpassword] = useState(null);
  const [newpassword, setnewpassword] = useState(null);
  const [oldpassword, setoldpassword] = useState(null);
  const [initialValues, setinitialValues] = useState(null);

  // =================================================GET PROFILE DATA========================================

  const { data, error, isLoading, isError, isSuccess, refetch } = useQueryHook(
    ["getUserById"],
    getUserById
  );

  useEffect(() => {
    if (data?.data) {
      setinitialValues(data?.data);
      setFile(data?.data?.profileImage && `${BASE_URL}${data?.data?.profileImage}`);
    }
    // setProfileData(data?.data);
  }, [data]);

  console.log(initialValues, "ini");

  // ==========================================logout=====================================================

  const {
    mutate,
    isSuccess: logoutSuccess,
    data: logoutData,
  } = useMutationHook(logOut);

  useEffect(() => {
    if (logoutSuccess) {
      if (logoutData?.success) {
        navigate("/");
        sessionStorage.clear();
      }
    }
  }, [logoutSuccess]);

  // =======================================DELETE===========================================================

  const {
    mutate: deleteMutate,
    isSuccess: deleteSuccess,
    data: deleteData,
  } = useMutationHook(deleteUser);

  useEffect(() => {
    if (deleteSuccess) {
      if (deleteData?.success) {
        navigate("/");
        refetch();
        sessionStorage.clear();
      }
    }
  }, [deleteSuccess]);

  // =======================================change password====================================================

  const {
    mutate: changePasswordMutate,
    isSuccess: changePasswordSuccess,
    data: changePasswordData,
    isPending: changePasswordPending,
  } = useMutationHook(changePassword);

  useEffect(() => {
    if (changePasswordSuccess) {
      if (changePasswordData?.success) {
        handleClosepass();
        setconfirmpassword(null);
        setoldpassword(null);
        setnewpassword(null);
      }
    }
  }, [changePasswordSuccess]);

  // ===========================================edit profile===========================================

  const {
    mutate: profileMutate,
    isPending,
    isError: profileerror,
    isSuccess: profilesuccess,
    data: profiledata,
  } = useMutationHook(editProfile);

  useEffect(() => {
    if (profilesuccess) {
      console.log(profiledata, "data");
      if (profiledata?.success) {
        refetch();
        handleClose();
      }
    }
  }, [profilesuccess]);

  // useEffect(()=>{

  // },[])

  console.log(file, "ininiiiiiiiiiiiiiiiiiiiiiiiiiiinininininininii");
  console.log(
    initialValues?.gender,
    "ininiiiiiiiiiiiiiiiiiiiiiiiiiiinininininininii"
  );

  return (
    <>
      <div className="main-header">
        <Container>
          {["lg"]?.map((expand) => {
            return (
              <>
                <Navbar
                  key={expand}
                  expand={expand}
                  className="main-navbar-cmn"
                >
                  <Container fluid>
                    <Navbar.Brand href="/">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="113"
                        height="27"
                        viewBox="0 0 113 27"
                        fill="none"
                      >
                        <path
                          d="M0 21.2444V0H4.68979V17.2969H14.5535V21.2444H0Z"
                          fill="#4B6C74"
                        />
                        <path
                          d="M24.5188 21.5759C22.7639 21.5759 21.2511 21.2645 19.9803 20.6417C18.7297 19.9989 17.7615 19.1049 17.0756 17.9598C16.41 16.7946 16.0772 15.4286 16.0772 13.8616C16.0772 12.3348 16.3999 10.9989 17.0454 9.8538C17.711 8.68862 18.6087 7.7846 19.7382 7.14174C20.888 6.47879 22.2092 6.14732 23.7019 6.14732C25.8602 6.14732 27.5747 6.83036 28.8455 8.19643C30.1163 9.54241 30.7517 11.3705 30.7517 13.6808V14.8259H20.4341C20.5955 15.9911 21.0191 16.8449 21.7049 17.3873C22.4109 17.9096 23.3791 18.1708 24.6096 18.1708C25.4164 18.1708 26.2333 18.0502 27.0604 17.8092C27.8874 17.5681 28.6337 17.1964 29.2994 16.6942L30.5096 19.7377C29.7633 20.3002 28.8455 20.7522 27.7563 21.0938C26.6872 21.4152 25.608 21.5759 24.5188 21.5759ZM23.8834 9.16072C22.9152 9.16072 22.1285 9.45201 21.5234 10.0346C20.9384 10.6172 20.5753 11.4308 20.4341 12.4754H26.9696C26.8486 10.2656 25.8198 9.16072 23.8834 9.16072Z"
                          fill="#4B6C74"
                        />
                        <path
                          d="M40.5496 27C39.1175 27 37.7761 26.8493 36.5255 26.548C35.2951 26.2467 34.2159 25.7746 33.288 25.1317L34.5286 22.0279C35.3354 22.5301 36.2431 22.9118 37.2517 23.173C38.2804 23.4542 39.2486 23.5949 40.1563 23.5949C42.7382 23.5949 44.0292 22.4096 44.0292 20.0391V18.3516C43.6257 19.115 42.9903 19.7277 42.123 20.1897C41.2556 20.6518 40.3076 20.8828 39.2789 20.8828C37.9476 20.8828 36.7877 20.5815 35.7993 19.9788C34.811 19.356 34.0445 18.4922 33.4998 17.3873C32.9552 16.2824 32.6829 14.9866 32.6829 13.5C32.6829 12.0335 32.9552 10.7478 33.4998 9.64286C34.0445 8.53795 34.811 7.68415 35.7993 7.08147C36.7877 6.4587 37.9476 6.14732 39.2789 6.14732C40.3479 6.14732 41.3061 6.37835 42.1532 6.8404C43.0004 7.30246 43.6257 7.91518 44.0292 8.67857V6.44866H48.4769V19.5268C48.4769 22.0179 47.8012 23.8862 46.4497 25.1317C45.0982 26.3772 43.1315 27 40.5496 27ZM40.6404 17.4777C41.649 17.4777 42.4558 17.1261 43.0609 16.423C43.6862 15.7199 43.9989 14.7455 43.9989 13.5C43.9989 12.2545 43.6862 11.2902 43.0609 10.6071C42.4558 9.90402 41.649 9.55246 40.6404 9.55246C39.6117 9.55246 38.7948 9.90402 38.1896 10.6071C37.5845 11.2902 37.2819 12.2545 37.2819 13.5C37.2819 14.7455 37.5845 15.7199 38.1896 16.423C38.7948 17.1261 39.6117 17.4777 40.6404 17.4777Z"
                          fill="#4B6C74"
                        />
                        <path
                          d="M59.1646 27C57.7324 27 56.3911 26.8493 55.1405 26.548C53.91 26.2467 52.8309 25.7746 51.903 25.1317L53.1435 22.0279C53.9504 22.5301 54.8581 22.9118 55.8666 23.173C56.8953 23.4542 57.8636 23.5949 58.7713 23.5949C61.3532 23.5949 62.6441 22.4096 62.6441 20.0391V18.3516C62.2407 19.115 61.6053 19.7277 60.7379 20.1897C59.8706 20.6518 58.9225 20.8828 57.8938 20.8828C56.5625 20.8828 55.4027 20.5815 54.4143 19.9788C53.4259 19.356 52.6594 18.4922 52.1148 17.3873C51.5702 16.2824 51.2979 14.9866 51.2979 13.5C51.2979 12.0335 51.5702 10.7478 52.1148 9.64286C52.6594 8.53795 53.4259 7.68415 54.4143 7.08147C55.4027 6.4587 56.5625 6.14732 57.8938 6.14732C58.9629 6.14732 59.921 6.37835 60.7682 6.8404C61.6154 7.30246 62.2407 7.91518 62.6441 8.67857V6.44866H67.0919V19.5268C67.0919 22.0179 66.4161 23.8862 65.0647 25.1317C63.7132 26.3772 61.7465 27 59.1646 27ZM59.2554 17.4777C60.2639 17.4777 61.0708 17.1261 61.6759 16.423C62.3012 15.7199 62.6139 14.7455 62.6139 13.5C62.6139 12.2545 62.3012 11.2902 61.6759 10.6071C61.0708 9.90402 60.2639 9.55246 59.2554 9.55246C58.2266 9.55246 57.4097 9.90402 56.8046 10.6071C56.1994 11.2902 55.8969 12.2545 55.8969 13.5C55.8969 14.7455 56.1994 15.7199 56.8046 16.423C57.4097 17.1261 58.2266 17.4777 59.2554 17.4777Z"
                          fill="#4B6C74"
                        />
                        <path
                          d="M78.3544 21.5759C76.5995 21.5759 75.0867 21.2645 73.8159 20.6417C72.5653 19.9989 71.5971 19.1049 70.9113 17.9598C70.2456 16.7946 69.9128 15.4286 69.9128 13.8616C69.9128 12.3348 70.2355 10.9989 70.881 9.8538C71.5467 8.68862 72.4443 7.7846 73.5739 7.14174C74.7236 6.47879 76.0448 6.14732 77.5375 6.14732C79.6958 6.14732 81.4104 6.83036 82.6811 8.19643C83.9519 9.54241 84.5873 11.3705 84.5873 13.6808V14.8259H74.2698C74.4311 15.9911 74.8547 16.8449 75.5406 17.3873C76.2465 17.9096 77.2148 18.1708 78.4452 18.1708C79.252 18.1708 80.069 18.0502 80.896 17.8092C81.723 17.5681 82.4693 17.1964 83.135 16.6942L84.3453 19.7377C83.5989 20.3002 82.6811 20.7522 81.5919 21.0938C80.5228 21.4152 79.4437 21.5759 78.3544 21.5759ZM77.719 9.16072C76.7508 9.16072 75.9641 9.45201 75.359 10.0346C74.7741 10.6172 74.411 11.4308 74.2698 12.4754H80.8052C80.6842 10.2656 79.6555 9.16072 77.719 9.16072Z"
                          fill="#4B6C74"
                        />
                        <path
                          d="M87.2447 21.2444V6.44866H91.7227V9.01005C92.4287 7.28237 93.9415 6.32813 96.2612 6.14732L97.5925 6.05692L97.8648 9.88393L95.293 10.1551C93.0338 10.3761 91.9042 11.5212 91.9042 13.5904V21.2444H87.2447Z"
                          fill="#4B6C74"
                        />
                        <path
                          d="M104.589 21.5759C103.479 21.5759 102.501 21.365 101.654 20.9431C100.807 20.5212 100.131 19.9487 99.6265 19.2254C99.1424 18.5022 98.9004 17.6886 98.9004 16.7846C98.9004 15.7199 99.1828 14.8661 99.7476 14.2232C100.312 13.5804 101.23 13.1283 102.501 12.8672C103.772 12.5859 105.456 12.4453 107.554 12.4453H108.643V11.933C108.643 11.0491 108.441 10.4263 108.038 10.0647C107.634 9.68304 106.949 9.49219 105.98 9.49219C105.174 9.49219 104.316 9.62277 103.409 9.88393C102.521 10.125 101.634 10.4967 100.746 10.9989L99.5055 7.95536C100.03 7.61384 100.665 7.30246 101.412 7.02121C102.178 6.73996 102.975 6.52902 103.802 6.38839C104.629 6.22768 105.416 6.14732 106.162 6.14732C108.461 6.14732 110.176 6.66964 111.306 7.71429C112.435 8.73884 113 10.3359 113 12.5056V21.2444H108.734V19.0145C108.431 19.798 107.917 20.4208 107.191 20.8828C106.485 21.3449 105.617 21.5759 104.589 21.5759ZM105.617 18.5324C106.465 18.5324 107.181 18.2411 107.766 17.6585C108.351 17.0759 108.643 16.3225 108.643 15.3984V14.7958H107.584C106.031 14.7958 104.932 14.9364 104.286 15.2176C103.641 15.4788 103.318 15.9408 103.318 16.6038C103.318 17.1663 103.509 17.6283 103.893 17.99C104.296 18.3516 104.871 18.5324 105.617 18.5324Z"
                          fill="#4B6C74"
                        />
                      </svg>
                    </Navbar.Brand>
                    <Navbar.Toggle
                      aria-controls={`offcanvasNavbar-expand-${expand}`}
                    />
                    <Navbar.Offcanvas
                      id={`offcanvasNavbar-expand-${expand}`}
                      aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                      placement="end"
                    >
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title
                          id={`offcanvasNavbarLabel-expand-${expand}`}
                        >
                          Offcanvas
                        </Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body className="justify-content-end">
                        <div className="start-btns">
                          {!sessionStorage?.getItem("token") ? (
                            <Link to="/create-account" className="sign-btn">
                              Sign Up
                            </Link>
                          ) : (
                            <>
                              <Nav className="justify-content-end flex-grow-1 pe-3">
                                <Nav.Link href="/">Home</Nav.Link>

                                <NavDropdown
                                  title=" Start Workout"
                                  id={`offcanvasNavbarDropdown-expand-${expand}`}
                                >
                                  <NavDropdown.Item href="/full-body-challenge">
                                    Start Workout
                                  </NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown
                                  title="Daily Diet"
                                  id={`offcanvasNavbarDropdown-expand-${expand}`}
                                >
                                  <NavDropdown.Item href="/full-body-challenge-diet">
                                    Daily Diet
                                  </NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link href="/about-us">About Us</Nav.Link>
                                <Nav.Link href="/blogs-listing">Blog</Nav.Link>
                              </Nav>
                            </>
                          )}

                          {!sessionStorage?.getItem("token") ? (
                            <Link to="/login" className="sign-btn">
                              Sign In
                            </Link>
                          ) : (
                            <>
                              <Dropdown className="notification">
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  <svg
                                    width="16"
                                    height="20"
                                    viewBox="0 0 16 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8.18875 0C8.65754 0.183734 8.84224 0.545142 8.80638 1.05293C8.79403 1.2277 8.80959 1.40444 8.8027 1.57989C8.79825 1.69244 8.83666 1.73901 8.94549 1.75936C11.265 2.19331 13.0251 3.96918 13.4942 6.37306C13.5743 6.78382 13.59 7.21208 13.5998 7.6331C13.6195 8.47873 13.5842 9.3266 13.6263 10.1706C13.7017 11.6829 14.3051 12.9517 15.3812 13.9665C15.7513 14.3154 16.0137 14.7007 15.9994 15.2406C15.9788 16.0248 15.3943 16.637 14.6367 16.671C14.593 16.6729 14.5492 16.6724 14.5055 16.6724C10.1691 16.6724 5.83278 16.6727 1.49642 16.6722C0.759624 16.6721 0.201356 16.2501 0.0433103 15.5479C-0.0899188 14.9562 0.0877802 14.4577 0.527365 14.0616C0.984939 13.6491 1.36711 13.172 1.67139 12.6274C2.17646 11.7234 2.40254 10.7451 2.39987 9.70248C2.39773 8.85653 2.37458 8.00861 2.41935 7.16489C2.51695 5.32489 3.31858 3.86547 4.73316 2.77283C5.42513 2.23833 6.20515 1.90283 7.05743 1.7593C7.16709 1.74081 7.20437 1.69201 7.19992 1.57971C7.19303 1.40426 7.20906 1.22745 7.19612 1.05275C7.15848 0.543596 7.34508 0.18361 7.81376 0C7.93879 0 8.06377 0 8.18875 0Z"
                                      fill="black"
                                      fill-opacity="0.3"
                                    />
                                    <path
                                      d="M5.07876 17.5066C7.03475 17.5066 8.97678 17.5066 10.9198 17.5066C10.7815 18.579 9.74208 19.9202 8.1463 19.9965C6.45992 20.0773 5.26026 18.7306 5.07876 17.5066Z"
                                      fill="black"
                                      fill-opacity="0.3"
                                    />
                                  </svg>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <div className="notification-box">
                                    <div className="notify-inner mb-2">
                                      <h3>Workout time</h3>
                                      <p>Its Day 2 for full body challenge</p>
                                    </div>
                                    <div className="notify-inner">
                                      <h3>Workout time</h3>
                                      <p>Its Day 2 for full body challenge</p>
                                    </div>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                              <Form className="d-flex">
                                <div className="nav-profile">
                                  <img
                                    style={{ borderRadius: "50%" }}
                                    src={
                                      data?.data?.profileImage
                                        ? `${BASE_URL}${data?.data?.profileImage}`
                                        : require("../Assets/images/uploading.png")
                                    }
                                  />
                                  <p>{data?.data?.name || data?.data?.email}</p>
                                </div>
                                <div className="thre-line-nav">
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant="success"
                                      id="dropdown-basic"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="15"
                                        viewBox="0 0 20 15"
                                        fill="none"
                                      >
                                        <path
                                          d="M9.99191 2.1501C7.06273 2.1501 4.13359 2.15077 1.20441 2.1484C1.03342 2.14825 0.856936 2.1478 0.692521 2.10538C0.228746 1.98572 -0.0366775 1.53457 0.00410644 0.972528C0.042884 0.437868 0.357013 0.0746608 0.834382 0.0185348C0.950507 0.00490157 1.06812 0.000641184 1.18504 0.000604137C7.05899 -0.000210894 12.9329 -0.000359082 18.8069 0.00115984C18.9626 0.00119689 19.1218 0.00149326 19.2734 0.0339834C19.7386 0.133676 20.026 0.581758 19.9981 1.13942C19.9706 1.68998 19.6574 2.07078 19.1768 2.12954C19.0453 2.14562 18.9119 2.14925 18.7794 2.14928C15.8502 2.15036 12.9211 2.1501 9.99191 2.1501Z"
                                          fill="#7D7D7D"
                                        />
                                        <path
                                          d="M9.97544 8.5751C7.04626 8.5751 4.11712 8.57588 1.18795 8.5731C1.01737 8.57295 0.840609 8.56928 0.677439 8.52353C0.212765 8.39324 -0.0401712 7.94741 0.00573226 7.38C0.0486263 6.84949 0.365765 6.49518 0.850779 6.44172C0.967008 6.4289 1.08459 6.4256 1.20151 6.42557C7.06764 6.42482 12.9338 6.42457 18.7999 6.42668C18.9708 6.42675 19.1474 6.42783 19.3115 6.47128C19.7765 6.59446 20.0368 7.0418 19.9947 7.60699C19.955 8.14091 19.6408 8.50145 19.1604 8.55643C19.0288 8.57147 18.8955 8.57436 18.7629 8.57439C15.8338 8.57532 12.9046 8.5751 9.97544 8.5751Z"
                                          fill="#7D7D7D"
                                        />
                                        <path
                                          d="M10.0014 12.8499C12.9305 12.8499 15.8597 12.8494 18.7888 12.8513C18.9523 12.8514 19.1197 12.8509 19.2786 12.8858C19.7443 12.9882 20.0278 13.4367 19.9977 13.9952C19.9681 14.5456 19.6545 14.9221 19.1713 14.9803C19.0475 14.9952 18.9221 14.9993 18.7973 14.9994C12.9312 15.0002 7.06505 15.0005 1.19891 14.9984C1.02803 14.9983 0.851401 14.9969 0.687401 14.9533C0.223454 14.8301 -0.0379574 14.3803 0.00452154 13.8167C0.0447174 13.2834 0.35985 12.9225 0.839778 12.8682C0.971401 12.8533 1.10475 12.8506 1.23731 12.8506C4.1587 12.8497 7.08006 12.8499 10.0014 12.8499Z"
                                          fill="#7D7D7D"
                                        />
                                      </svg>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item onClick={handleShow}>
                                        Edit Profile
                                      </Dropdown.Item>
                                      <Dropdown.Item href="/exercise-completed">
                                        Progress report
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={handleShowsubs}>
                                        Subscription
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={handleShowpass}>
                                        Change password
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={() => mutate()}>
                                        Log out
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => deleteMutate()}
                                      >
                                        Delete account
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </Form>
                            </>
                          )}
                        </div>
                      </Offcanvas.Body>
                    </Navbar.Offcanvas>
                  </Container>
                </Navbar>
              </>
            );
          })}
        </Container>

        <Modal show={show} onHide={handleClose} centered>
          <>
            <Formik
              initialValues={{
                profileImage: initialValues?.profileImage || "",
                gender: initialValues?.gender ?? "",
                age: initialValues?.age ?? "",
                mainGoal: initialValues?.mainGoal || "",
                focusedArea: initialValues?.focusedArea || "",
                weeklyTrainingDays: initialValues?.weeklyTrainingDays || "",
                firstDayOfWeek: initialValues?.firstDayOfWeek || "",
                weight: initialValues?.weight || "",
                height: initialValues?.height || "",
              }}
              validationSchema={yup.object().shape({
                profileImage: yup.mixed().required("Required"),
                gender: yup.string().required("Required"),
                age: yup
                  .number()
                  .required("Required")
                  .positive("Age must be a positive number"),

                age: yup.number().required("Required"),
                // .typeError("Age must be number"),
                mainGoal: yup.string().required("Required"),
                focusedArea: yup.string().required("Required"),
                weeklyTrainingDays: yup
                  .number()
                  .required("Required")
                  .typeError("Weekly training days must be number"),
                firstDayOfWeek: yup.string().required("Required"),
                weight: yup
                  .number()
                  .required("Required")
                  .typeError("Weight must be number"),
                height: yup
                  .number()
                  .required("Required")
                  .typeError("Weight training days must be number"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                const originalValues = { ...initialValues };
                const updatedValues = {};

                Object.keys(values).forEach((key) => {
                  if (values[key] !== originalValues[key]) {
                    updatedValues[key] = values[key];
                  }
                });

                console.log("onSubmit called");
                console.log(values, "values");
                let formData = new FormData();

                Object.keys(updatedValues).forEach((key) => {
                  formData.append(key, updatedValues[key]);
                });

                // formData.append("age", values?.age);
                // formData.append("firstDayOfWeek", values?.firstDayOfWeek);
                // formData.append("focusedArea", values?.focusedArea);
                // formData.append("gender", values?.gender);
                // formData.append("height", values?.height);
                // formData.append("mainGoal", values?.mainGoal);
                // formData.append("profileImage", values?.profileImage);
                // formData.append(
                //   "weeklyTrainingDays",
                //   values?.weeklyTrainingDays
                // );
                // formData.append("weight", values?.weight);
                // console.log(values?.profileImage,"imageeeee");

                profileMutate(formData);
                console.log("jhgfgdhfdhfgdhfhd")
                // Add your submission logic here
                setSubmitting(false);
              }}
              // validateOnBlur={false}
              enableReinitialize={true}
              className="modal-cmn"
            >
              {({
                setFieldValue,
                values,
                errors,
                handleSubmit,
                isSubmitting,
              }) => (
                <FormikForm>
                  <Modal.Body className="px-4 cmn-modal-form">
                    <h2>Edit profile</h2>

                    {console.log(values, "innervalues")}
                    {console.log(errors, "errors")}

                    <div className="image-upload">
                      <div className="image-upload-inner">
                        <img
                          className="stat-img"
                          src={require("../Assets/images/uploading.png")}
                        />
                        {
                          file &&  <img className="view-img" src={file} />
                        }
                       
                        <div className="upload-in">
                          <input
                            type="file"
                            name="profileImage"
                            onChange={(e) => {
                              setFieldValue(
                                "profileImage",
                                e?.target?.files[0]
                                  ? e?.target?.files[0]
                                  : values.profileImage
                              );

                              setFile(URL.createObjectURL(e?.target?.files[0]));
                            }}
                          />
                          <img
                            src={require("../Assets/images/camera.svg").default}
                          />
                        </div>
                      </div>
                    </div>
                    <h3>Upload Profile Picture</h3>
                    <ErrorMessage
                      name="profileImage"
                      component="div"
                      className="text-danger"
                    />

                    <div className="cmn-head-box pop-set">
                      <h4>Gender</h4>
                      <div className="gender-toggle justify-content-start">
                        <div className="toggle-btn mb-2">
                          <Field
                            checked={values?.gender == 1}
                            type="radio"
                            name="gender"
                            value="1"
                          />
                          <button type="button"> Male</button>
                        </div>

                        <div className="toggle-btn mb-2">
                          <Field
                            checked={values?.gender == 0}
                            type="radio"
                            name="gender"
                            value="0"
                          />
                          <button type="button"> Female</button>
                        </div>
                      </div>
                      <ErrorMessage
                        name="gender"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="cmn-head-box">
                      <h4>Age</h4>
                      <div className="gender-toggle justify-content-start">
                        <Field
                          type="number"
                          name="age"
                          className="form-control"
                          value={values.age}
                        />
                      </div>
                      <ErrorMessage
                        name="age"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="cmn-head-box pop-set">
                      <h4>Main goal</h4>
                      <div className="gender-toggle justify-content-start flex-wrap gender-wrap">
                        <div className="toggle-btn mb-2">
                          <Field
                            checked={values.mainGoal == "1"}
                            type="radio"
                            name="mainGoal"
                            value="1"
                          />
                          <button type="button"> Lose weight</button>
                        </div>
                        <div className="toggle-btn mb-2">
                          <Field
                            checked={values.mainGoal == "2"}
                            type="radio"
                            name="mainGoal"
                            value="2"
                          />
                          <button type="button">Build Muscles</button>
                        </div>
                        <div className="toggle-btn mb-2">
                          <Field
                            checked={values.mainGoal == "3"}
                            type="radio"
                            name="mainGoal"
                            value="3"
                          />
                          <button type="button"> Get toned</button>
                        </div>
                        <div className="toggle-btn mb-2">
                          <Field
                            checked={values.mainGoal == "4"}
                            type="radio"
                            name="mainGoal"
                            value="4"
                          />
                          <button type="button"> Healthy Lifestyle</button>
                        </div>
                      </div>
                      <ErrorMessage
                        name="mainGoal"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="cmn-head-box pop-set">
                      <h4>Focus area</h4>
                      <div className="gender-toggle justify-content-start flex-wrap">
                        <div className="toggle-btn mb-2">
                          <Field
                            checked={values.focusedArea == "1"}
                            type="radio"
                            name="focusedArea"
                            value="1"
                          />
                          <button type="button"> Full body</button>
                        </div>
                        <div className="toggle-btn mb-2">
                          <Field
                            checked={values.focusedArea == "2"}
                            type="radio"
                            name="focusedArea"
                            value="2"
                          />
                          <button type="button"> Arms</button>
                        </div>
                        <div className="toggle-btn mb-2">
                          <Field
                            checked={values.focusedArea == "3"}
                            type="radio"
                            name="focusedArea"
                            value="3"
                          />
                          <button type="button"> Abs</button>
                        </div>
                        <div className="toggle-btn mb-2">
                          <Field
                            checked={values.focusedArea == "4"}
                            type="radio"
                            name="focusedArea"
                            value="4"
                          />
                          <button type="button"> Butt</button>
                        </div>
                        <div className="toggle-btn mb-2">
                          <Field
                            checked={values.focusedArea == "5"}
                            type="radio"
                            name="focusedArea"
                            value="5"
                          />
                          <button type="button"> Leg</button>
                        </div>
                      </div>
                      <ErrorMessage
                        name="focusedArea"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="cmn-head-box pop-set">
                      <h4>Weekly training days</h4>
                      <div className="gender-toggle justify-content-start flex-wrap">
                        <div className="toggle-btn mb-2">
                          <Field
                            type="radio"
                            name="weeklyTrainingDays"
                            value="1"
                          />
                          <button type="button"> 1</button>
                        </div>
                        <div className="toggle-btn mb-2">
                          <Field
                            value="2"
                            type="radio"
                            name="weeklyTrainingDays"
                          />
                          <button type="button">2</button>
                        </div>
                        <div className="toggle-btn mb-2">
                          <Field
                            value="3"
                            type="radio"
                            name="weeklyTrainingDays"
                          />
                          <button type="button"> 3</button>
                        </div>
                        <div className="toggle-btn mb-2">
                          <Field
                            value="4"
                            type="radio"
                            name="weeklyTrainingDays"
                          />
                          <button type="button"> 4</button>
                        </div>
                        <div className="toggle-btn mb-2">
                          <Field
                            value="5"
                            type="radio"
                            name="weeklyTrainingDays"
                          />
                          <button type="button"> 5</button>
                        </div>
                        <div className="toggle-btn mb-2">
                          <Field
                            value="6"
                            type="radio"
                            name="weeklyTrainingDays"
                          />
                          <button type="button"> 6</button>
                        </div>
                        <div className="toggle-btn mb-2">
                          <Field
                            value="7"
                            type="radio"
                            name="weeklyTrainingDays"
                          />
                          <button type="button"> 7</button>
                        </div>
                      </div>
                      <ErrorMessage
                        name="weeklyTrainingDays"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="cmn-head-box pop-set">
                      <h4>First day of week</h4>

                      <div className="gender-toggle justify-content-start">
                        <Form.Select
                          name="firstDayOfWeek"
                          value={values.firstDayOfWeek}
                          onChange={(e) => {
                            setFieldValue("firstDayOfWeek", e.target.value);
                            console.log(e.target.value);
                          }}
                          aria-label="Default select example"
                        >
                          <option>Select Day</option>
                          <option value="7">Sunday</option>
                          <option value="1">Monday</option>
                          <option value="2">Tuesday</option>
                          <option value="3">Wednesday</option>
                          <option value="4">Thursday</option>
                          <option value="5">Friday</option>
                          <option value="6">Saturday</option>
                        </Form.Select>
                      </div>
                      <ErrorMessage
                        name="firstDayOfWeek"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="cmn-head-box pop-set">
                      <h4>Weight</h4>
                      <div className="gender-toggle justify-content-start">
                        <Field
                          name="weight"
                          type="number"
                          className="form-control"
                          placeholder="67"
                        />
                      </div>
                      <ErrorMessage
                        name="weight"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="cmn-head-box">
                      <h4>Height</h4>
                      <div className="gender-toggle justify-content-start">
                        <Field
                          name="height"
                          type="number"
                          className="form-control"
                          placeholder="176"
                        />
                      </div>
                      <ErrorMessage
                        name="height"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      type="submit"
                      className="green-btn"
                    >
                      {isPending ? <div className="loading"></div> : "Save"}
                    </Button>
                  </Modal.Footer>
                </FormikForm>
              )}
            </Formik>
          </>
        </Modal>

        <Modal show={showsubs} onHide={handleClosesubs} centered>
          <Modal.Body className="px-4">
            <div className="modal-cmn">
              <h2>Subscription</h2>
              <div className="subs-main">
                <h3>$.99/m</h3>
                <ul>
                  <li>
                    <img src={require("../Assets/images/tick.svg").default} />
                    <p>Workout Plan</p>
                  </li>
                  <li>
                    <img src={require("../Assets/images/tick.svg").default} />
                    <p>Nutrition Plan</p>
                  </li>
                  <li>
                    <img src={require("../Assets/images/tick.svg").default} />
                    <p>Health Tips</p>
                  </li>
                  <li>
                    <img src={require("../Assets/images/tick.svg").default} />
                    <p>Expert Guidance</p>
                  </li>
                </ul>
                <h6>*your plan expires on 01/01/2024</h6>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="green-btn" onClick={handleClose}>
              Cancel Subscription
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showpass} onHide={handleClosepass} centered>
          <Modal.Body className="px-4">
            <div className="modal-cmn">
              <h2>Change Password</h2>
              <div className="fields-modal">
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Old Password</Form.Label>
                    <Form.Control
                      name="oldpassword"
                      value={oldpassword}
                      onChange={(e) => setoldpassword(e.target.value)}
                      type="password"
                      placeholder="Enter here"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      name="newpassword"
                      value={newpassword}
                      onChange={(e) => setnewpassword(e.target.value)}
                      type="password"
                      placeholder="Enter here"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      name="confirmpassword"
                      value={confirmpassword}
                      onChange={(e) => setconfirmpassword(e.target.value)}
                      type="password"
                      placeholder="Enter here"
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="green-btn"
              onClick={() => {
                if (!oldpassword) {
                  toast.error("Please enter old password");
                } else if (!newpassword) {
                  toast.error("Please enter new password");
                } else if (!confirmpassword) {
                  toast.error("Please enter confirm password");
                } else if (newpassword !== confirmpassword) {
                  toast.error("Password did not match");
                } else {
                  changePasswordMutate({
                    newpassword,
                    oldpassword,
                  });
                }
              }}

              // onClick={handleClosepass}
            >
              {changePasswordPending ? <div className="loading"></div> : "Save"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
