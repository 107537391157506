import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { userprofileData } from "../redux/Reducer/profileCreation";
import { useDispatch, useSelector } from "react-redux";

export default function Motivation() {

  const dispatch=useDispatch()
  const navigate=useNavigate()

  const profileData = useSelector((state) => state.profilecreation.profileData);
  console.log(profileData, "profileDataq");

  useEffect(() => {
    if (profileData && profileData.birthDate) {
      setwhatMotivatesYou(profileData.whatMotivatesYou);
    }
    
  }, [profileData]);
  //========================================react state==============================================

  const [whatMotivatesYou, setwhatMotivatesYou] = useState(null);

  // ========================================handlesave===============================================

  const handlesave=()=>{
    if(!whatMotivatesYou){
      toast.error("Please select what motivates you")
    }else{
      console.log(whatMotivatesYou)
      dispatch(userprofileData({...profileData,whatMotivatesYou}))
      navigate("/pushups")
    }
  }

  return (
    <div>
      <Container fluid>
        <div className="main-form">
          <Row>
            <Col lg={7} className="px-0">
              <div className="left-img">
                <img src={require("../Assets/images/leftimg.png")} />
              </div>
            </Col>
            <Col lg={5} className="form-right">
              <div className="logo">
                <img src={require("../Assets/images/LOGO.png")} />
              </div>
              <div className="cmn-form-wrap">
                <div className="cmn-form">
                  <h2 className="heading-30">
                    What Motivates you <br />
                    the most?
                  </h2>
                  <div className="form-outer">
                    <div className="gender-toggle flex-column align-items-center">
                      <div className="toggle-btn mb-2">
                        <input
                          value="1"
                          checked={whatMotivatesYou=="1"}
                          onChange={(e) => setwhatMotivatesYou(e.target.value)}
                          type="radio"
                          name="radio"
                        />
                        <button type="button">Feel Confident</button>
                      </div>
                      <div className="toggle-btn mb-2">
                        <input
                          value="2"
                          checked={whatMotivatesYou=="2"}
                          onChange={(e) => setwhatMotivatesYou(e.target.value)}
                          type="radio"
                          name="radio"
                        />
                        <button type="button"> Release stress</button>
                      </div>
                      <div className="toggle-btn mb-2">
                        <input
                          value="3"
                          checked={whatMotivatesYou=="3"}
                          onChange={(e) => setwhatMotivatesYou(e.target.value)}
                          type="radio"
                          name="radio"
                        />
                        <button type="button">Improved health</button>
                      </div>
                      <div className="toggle-btn mb-2">
                        <input
                          value="4"
                          checked={whatMotivatesYou=="4"}
                          onChange={(e) => setwhatMotivatesYou(e.target.value)}
                          type="radio"
                          name="radio"
                        />
                        <button type="button">Boost energy</button>
                      </div>
                    </div>
                  </div>
                  <div
                  // to="/pushups"
                  onClick={handlesave}
                  style={{cursor:"pointer"}}
                   className="cmn-green-btn mt-5 mb-4">
                    Next
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
