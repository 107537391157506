import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { userprofileData } from "../redux/Reducer/profileCreation";

export default function Gender() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileData = useSelector((state) => state.profilecreation.profileData);
  console.log(profileData, "profileDataq");

  useEffect(() => {
    if (profileData && profileData.gender) {
      setGender(profileData.gender);
    }
  }, [profileData]);

  // =========================================react state========================================================

  const [gender, setGender] = useState(null);

  // ========================================handlesubmit=======================================================

  const handlesave = () => {
    if (!gender) {
      toast.error("Please select gender");
    } else {
      console.log(gender);
      dispatch(userprofileData({ gender }));
      navigate("/age");
    }
  };

  console.log(gender);

  return (
    <div>
      <Container fluid>
        <div className="main-form">
          <Row>
            <Col lg={7} className="px-0">
              <div className="left-img">
                <img src={require("../Assets/images/leftimg.png")} />
              </div>
            </Col>
            <Col lg={5} className="form-right">
              <div className="logo">
                <img src={require("../Assets/images/LOGO.png")} />
              </div>
              <div className="cmn-form-wrap">
                <div className="cmn-form">
                  <h2 className="heading-30">What’s Your Gender?</h2>
                  <div className="form-outer">
                    <div className="gender-toggle flex-nowrap">
                      <div className="toggle-btn">
                        <input
                          checked={gender === "1"}
                          value="1"
                          onChange={(e) => setGender(e.target.value)}
                          type="radio"
                          name="radio"
                        />
                        <button type="button"> Male</button>
                      </div>
                      <div className="toggle-btn">
                        <input
                          checked={gender === "0"}
                          value="0"
                          onChange={(e) => setGender(e.target.value)}
                          type="radio"
                          name="radio"
                        />
                        <button type="button"> Female</button>
                      </div>
                    </div>
                  </div>
                  <div
                    // to="/age"
                    style={{ cursor: "pointer" }}
                    onClick={handlesave}
                    className="cmn-green-btn mt-5 mb-4"
                  >
                    Next
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
