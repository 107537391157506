import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { userprofileData } from "../redux/Reducer/profileCreation";
import { editProfile } from "../redux/Actions/userActions";
import useMutationHook from "../myHooks/mutationHook";

export default function HeightWeight() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileData = useSelector((state) => state.profilecreation.profileData);
  console.log(profileData, "profileDataq");

  useEffect(() => {
    if (profileData && profileData.birthDate) {
      setheight(profileData.height);
      setweight(profileData.weight);
    }
  }, [profileData]);
  // ==================================================react state=====================================================

  const [weight, setweight] = useState(null);
  const [height, setheight] = useState(null);

  // ===========================================edit profile===========================================

  const { mutate, isPending, isError, isSuccess, data } =
    useMutationHook(editProfile);

  useEffect(() => {
    if (isSuccess) {
      console.log(data, "data");
      if (data?.success) {
        navigate("/plan-ready");
      }
    }
  }, [isSuccess]);

  // ====================================================handlesave===================================================

  const handlesave = () => {
    if (!weight) {
      toast.error("Please enter your weight");
    } else if (!height) {
      toast.error("Please enter your height");
    } else {
      console.log(height, weight);
      dispatch(userprofileData({ ...profileData, height, weight }));
      mutate({ ...profileData, height, weight });
    }
  };

  return (
    <div>
      <Container fluid>
        <div className="main-form">
          <Row>
            <Col lg={7} className="px-0">
              <div className="left-img">
                <img src={require("../Assets/images/leftimg.png")} />
              </div>
            </Col>
            <Col lg={5} className="form-right">
              <div className="logo">
                <img src={require("../Assets/images/LOGO.png")} />
              </div>
              <div className="cmn-form-wrap">
                <div className="cmn-form">
                  <h2 className="heading-30 mb-3">
                    let us know <br />
                    you better
                  </h2>
                  <p className="sub-16 width-set">
                    Let us know you better to help boost your workout results
                  </p>
                  <div className="form-outer flex-column">
                    <div className="input-date height">
                      <label className="mb-2">Weight</label>
                      <div>
                        <input
                          value={weight}
                          onChange={(e) => setweight(e.target.value)}
                          type="number"
                          className="form-control"
                          placeholder="Enter here"
                        />
                        <span>kg/lb</span>
                      </div>
                    </div>
                    <div className="input-date mt-4 height">
                      <label className="mb-2">Height</label>
                      <div>
                        <input
                          value={height}
                          onChange={(e) => setheight(e.target.value)}
                          type="number"
                          className="form-control"
                          placeholder="Enter here"
                        />
                        <span>cm/ft</span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    // to="#"
                    onClick={handlesave}
                    className="cmn-green-btn mt-5 mb-4"
                  >
                    Get my Plan
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
