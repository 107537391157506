import { useEffect, useState } from "react";

const CountdownTimer = ({ targetDate }) => {
  const countDownDate = new Date(targetDate).getTime();
  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  const getReturnValues = (countDown) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
  };

  const [days, hours, minutes, seconds] = getReturnValues(countDown);

  return (
    <>
      <p>
        {days} <span>Days</span>
      </p>
      <span className="border-span"></span>
      <p>
        {hours} <span>Hours</span>
      </p>
      <span className="border-span"></span>
      <p>
        {minutes} <span>Minutes</span>
      </p>
      <span className="border-span"></span>
      <p>
        {seconds} <span>Seconds</span>
      </p>
    </>
  );
};

export default CountdownTimer;
