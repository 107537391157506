import { createSlice } from "@reduxjs/toolkit";


export const userDetailSlice = createSlice({
    name:"userDetailSlice",
    initialState:{
        userId:"",

    },
    reducers:{
        userIdforGetDetails:(state,action)=>{
            console.log(action.payload,"PAYLOAD")
            state.userId=action.payload
        }
    }
})

export const {userIdforGetDetails} = userDetailSlice.actions

export default userDetailSlice.reducer