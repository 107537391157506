import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { userprofileData } from "../redux/Reducer/profileCreation";

export default function FocusFemale() {

  const dispatch=useDispatch()
  const navigate=useNavigate()

  const profileData = useSelector((state) => state.profilecreation.profileData);
  console.log(profileData, "profileDataq");

  useEffect(() => {
    if (profileData && profileData.birthDate) {
      setFocusedArea(profileData.focusedArea);
    }
    
  }, [profileData]);

  //=========================================react state================================================

  const [focusedArea,setFocusedArea] = useState(null)



  //========================================handlesave=================================================

  const handlesave = ()=>{
    if(!focusedArea){
      toast.error("Please select your focused area")
    }else{
      console.log(focusedArea)
      dispatch(userprofileData({...profileData,focusedArea}))
      navigate("/main-female")
    }
  }


  return (
    <div>
      <Container fluid>
        <div className="main-form">
          <Row>
            <Col lg={7} className="px-0">
              <div className="left-img">
                <img src={require("../Assets/images/leftimg.png")} />
              </div>
            </Col>
            <Col lg={5} className="form-right">
              <div className="logo">
                <img src={require("../Assets/images/LOGO.png")} />
              </div>
              <div className="cmn-form-wrap">
                <div className="cmn-form">
                  <h2 className="heading-30">
                    Please choose your <br />
                    focus area
                  </h2>
                  <div className="form-outer">
                    <div className="gender-toggle flex-column align-items-center">
                      <div className="toggle-btn mb-2">
                        <input checked={focusedArea=="1"} value="1" onChange={(e)=>setFocusedArea(e.target.value)} type="radio" name="radio" />
                        <button type="button"> Full body</button>
                      </div>
                      <div className="toggle-btn mb-2">
                        <input  checked={focusedArea=="2"} value="2" onChange={(e)=>setFocusedArea(e.target.value)} type="radio" name="radio" />
                        <button type="button"> Arm</button>
                      </div>
                      <div className="toggle-btn mb-2">
                        <input  checked={focusedArea=="3"} value="3" onChange={(e)=>setFocusedArea(e.target.value)} type="radio" name="radio" />
                        <button type="button"> Abs</button>
                      </div>
                      <div className="toggle-btn mb-2">
                        <input  checked={focusedArea=="4"} value="4" onChange={(e)=>setFocusedArea(e.target.value)} type="radio" name="radio" />
                        <button type="button"> Butt</button>
                      </div>
                      <div className="toggle-btn mb-2">
                        <input  checked={focusedArea=="5"} value="5" onChange={(e)=>setFocusedArea(e.target.value)} type="radio" name="radio" />
                        <button type="button">Leg</button>
                      </div>
                    </div>
                  </div>
                  <div
                  // to="/main-female"
                  style={{cursor:"pointer"}}
                  onClick={handlesave}
                   className="cmn-green-btn mt-5 mb-4">
                    Next
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
