import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import useMutationHook from "../myHooks/mutationHook";
import { login } from "../redux/Actions/userActions";
import { userIdforGetDetails } from "../redux/Reducer/userDetailsSlics";
import { useDispatch } from "react-redux";
import GoogleLogin from "../Components/GoogleLogin";

export default function LoginScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // ====================================react state=============================================

  const [loginData, setloginData] = useState({
    email: "",
    password: "",
  });
  const [rememberme, setRememberMe] = useState(null);

  //===================================handlechange===============================================

  const handlechange = (e) => {
    setloginData((old) => ({ ...old, [e.target.name]: e.target.value }));
    // setRegistrationData((old) => ({ ...old, term: e.target.checked }));
  };

  // ============================custom hook for login====================================================

  const { mutate, isPending, isError, isSuccess, data } =
    useMutationHook(login);

  useEffect(() => {
    if (isSuccess) {
      console.log(data, "data");
      if (data?.success) {
        if (data?.data?.data?.isEmailVerified == 0) {
          navigate("/otp");
        } else if (data?.data?.data?.isProfileCompleted == 0) {
          sessionStorage.setItem("token", data?.data?.token);

          window.location.href = "/gender";
          dispatch(userIdforGetDetails(data?.data?.data?._id));
        } else {
          sessionStorage.setItem("token", data?.data?.token);
          window.location.href = "/";
          dispatch(userIdforGetDetails(data?.data?.data?._id));
        }
      }
    }
  }, [isSuccess]);

  //======================================handlesubmit============================================

  const handlesubmit = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!loginData?.email) {
      toast.error("Please enter your email");
    } else if (!emailRegex.test(loginData?.email)) {
      toast.error("Please Enter a Valid Email");
    } else if (!loginData?.password) {
      toast.error("Please enter your password");
    } else {
      console.log(loginData);
      mutate(loginData);
    }
  };

  // ===================================remember me==================================================

  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
    if (e.target.checked) {
      localStorage.setItem("remeberMe", rememberme);
      localStorage.setItem("email", loginData?.email);
      localStorage.setItem("password", loginData?.password);
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
      localStorage.removeItem("remeberMe");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("remeberMe")) {
      setRememberMe(true);
      const storedEmail = localStorage.getItem("email");
      const storedPassword = localStorage.getItem("password");
      setloginData((old) => ({
        ...old,
        email: storedEmail,
        password: storedPassword,
      }));
    }
  }, [rememberme]);

  console.log(rememberme, "rememberme");
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Container fluid>
        <div className="main-form">
          <Row>
            <Col lg={7} className="px-0">
              <div className="left-img">
                <img src={require("../Assets/images/leftimg.png")} />
              </div>
            </Col>
            <Col lg={5} className="form-right">
              <div className="logo">
                <img src={require("../Assets/images/LOGO.png")} />
              </div>
              <div className="cmn-form-wrap">
                <div className="cmn-form">
                  <h2 className="heading-30">
                    Welcome back!
                    <br /> Glad to see you, Again!
                  </h2>

                  <div className="cmn-form-fields">
                    <Form.Group className="mb-3 form-inner">
                      <label>Email address:</label>
                      <Form.Control
                        name="email"
                        value={loginData?.email}
                        onChange={handlechange}
                        type="text"
                        placeholder="Enter here"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 form-inner">
                      <label>Password:</label>
                      <Form.Control
                        name="password"
                        value={loginData?.password}
                        onChange={handlechange}
                        type="password"
                        placeholder="Enter here"
                      />
                    </Form.Group>
                    <div className="rem-for">
                      <div className="form-check">
                        <input
                          onChange={handleRememberMe}
                          checked={rememberme}
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          Remember Password
                        </label>
                      </div>
                      <Link to="/forgot-password" className="forgot-password">
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  <Link
                    to="#"
                    onClick={handlesubmit}
                    className="cmn-green-btn mt-5 mb-4"
                  >
                    {isPending ? <div className="loader"></div> : "Sign In"}
                  </Link>
                  {/* <div style={{cursor:"pointer"}} className="goggle-bnt">
                    <img src={require("../Assets/images/google.png")} />
                    <p className="mb-0">Continue with Google</p>
                  </div> */}
                  <GoogleLogin />
                  <div className="form-bottom">
                    <h2>
                      Don’t have an account?{" "}
                      <Link to="/create-account">Register Now</Link>
                    </h2>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
