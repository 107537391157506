import { createSlice } from "@reduxjs/toolkit";


export const profileCreationSlice = createSlice({
    name:"profileCreationSlice",
    initialState:{
        profileData:"",

    },
    reducers:{
        userprofileData:(state,action)=>{
            console.log(action.payload,"PAYLOAD")
            state.profileData=action.payload
        }
    }
})

export const {userprofileData} = profileCreationSlice.actions

export default profileCreationSlice.reducer