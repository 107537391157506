import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { userprofileData } from "../redux/Reducer/profileCreation";

export default function Pushups() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileData = useSelector((state) => state.profilecreation.profileData);
  console.log(profileData, "profileDataq");

  useEffect(() => {
    if (profileData && profileData.birthDate) {
      setpushUpCount(profileData.pushUpCount);
    }
  }, [profileData]);

  //=================================================react state======================================================

  const [pushUpCount, setpushUpCount] = useState(null);

  // ===============================================handlesave=======================================================

  const handlesave = () => {
    if (!pushUpCount) {
      toast.error("Please select push up counts");
    } else {
      console.log(pushUpCount);
      dispatch(userprofileData({ ...profileData, pushUpCount }));
      navigate("/weekly-goal");
    }
  };

  return (
    <div>
      <Container fluid>
        <div className="main-form">
          <Row>
            <Col lg={7} className="px-0">
              <div className="left-img">
                <img src={require("../Assets/images/leftimg.png")} />
              </div>
            </Col>
            <Col lg={5} className="form-right">
              <div className="logo">
                <img src={require("../Assets/images/LOGO.png")} />
              </div>
              <div className="cmn-form-wrap">
                <div className="cmn-form">
                  <h2 className="heading-30">
                    How many Push-ups
                    <br /> can you do at one time?
                  </h2>
                  <div className="form-outer">
                    <div className="gender-toggle flex-column align-items-center push-div">
                      <div className="toggle-btn mb-2">
                        <input
                          checked={pushUpCount == "1"}
                          value="1"
                          onChange={(e) => setpushUpCount(e.target.value)}
                          type="radio"
                          name="radio"
                        />
                        <button type="button">Beginner(3-5 push-ups)</button>
                      </div>
                      <div className="toggle-btn mb-2">
                        <input
                          checked={pushUpCount == "2"}
                          value="2"
                          onChange={(e) => setpushUpCount(e.target.value)}
                          type="radio"
                          name="radio"
                        />
                        <button type="button">
                          Intermediate(5-10 push-ups)
                        </button>
                      </div>
                      <div className="toggle-btn mb-2">
                        <input
                          checked={pushUpCount == "3"}
                          value="3"
                          onChange={(e) => setpushUpCount(e.target.value)}
                          type="radio"
                          name="radio"
                        />
                        <button type="button">Advanced(At least 10)</button>
                      </div>
                    </div>
                  </div>
                  <div
                    // to="/weekly-goal"
                    onClick={handlesave}
                    style={{ cursor: "pointer" }}
                    className="cmn-green-btn mt-5 mb-4"
                  >
                    Next
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
