import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Layout from "../Components/Layout";

export default function AboutUs() {
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Container>
        <div className="about-us-main">
          <Row>
            <Col lg={12}>
              <h2>About us</h2>
            </Col>
            <Col lg={6}>
              <img
                className="about-img"
                src={require("../Assets/images/fitness2.png")}
              />
            </Col>
            <Col lg={6}>
              <h3>Lorium ipsum</h3>
              <p>
                Lorem Ipsum is simply dummy text for printing and typesetting
                industry. Lorem ipsum has been industry’s standard dummy text
                ever since 1500s, when an unknown printer took galley of it and
                scrambled it to make a specimen copy. Lorem Ipsum is simply
                dummy text for printing and typesetting industry. when an
                unknown printer took galley of it and scrambled it to make a
                specimen copy. Lorem Ipsum is simply dummy text for printing and
                typesetting industry.
              </p>
            </Col>
            <Col lg={6} className="space-set-top">
              <h3>Lorium ipsum</h3>
              <p>
                Lorem Ipsum is simply dummy text for printing and typesetting
                industry. Lorem ipsum has been industry’s standard dummy text
                ever since 1500s, when an unknown printer took galley of it and
                scrambled it to make a specimen copy. Lorem Ipsum is simply
                dummy text for printing and typesetting industry. when an
                unknown printer took galley of it and scrambled it to make a
                specimen copy. Lorem Ipsum is simply dummy text for printing and
                typesetting industry.
              </p>
            </Col>
            <Col lg={6} className="space-set-top">
              <img
                className="about-img"
                src={require("../Assets/images/fitness2.png")}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
}
