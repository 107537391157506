import axios from "axios"


const userApi = axios.create({
    baseURL: process.env.REACT_APP_ADMIN_BASE_URL,
    headers:{
        Authorization:sessionStorage.getItem("token")
    }
})


userApi.interceptors.response.use(
    (response)=>{
        return response;
    },
    (error)=>{
        return error.response;
    }
)

export default userApi