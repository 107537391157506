import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../redux/Actions/userActions";
import useMutationHook from "../myHooks/mutationHook";

export default function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  

  //============================================react state==========================================

  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [show, setShow] = useState(false);
  const {id} = useParams() 

  // useEffect(() => {
  //   if (location) {
  //     setUserId(location?.state?.userId);
  //   }
  // }, [location]);

  // console.log(userId)

  // =========================================open modal===========================================

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ==============================================resetPassword====================================

  const {
    mutate: resetmutate,
    isPending: resetisPending,
    isError: resetisError,
    isSuccess: resetisSuccess,
    data: resetdata,
  } = useMutationHook(resetPassword);

  useEffect(() => {
    if (resetisSuccess) {
      console.log(resetdata, "resetdata");
      if (resetdata?.success) {
        handleShow();
      }
    }
  }, [resetisSuccess]);

  //=========================================handlesubmit============================================

  const handleSubmit = () => {
    if (!newPassword) {
      toast.error("Enter your new password");
    } else if (!confirmPassword) {
      toast.error("Enter your confirm your password");
    } else if (newPassword !== confirmPassword) {
      toast.error("Password did not match");
    } else {
      resetmutate({
        userId: id,
        password: newPassword,
      });
    }
  };

  return (
    <div>
      <Container fluid>
        <div className="main-form">
          <Row>
            <Col lg={7} className="px-0">
              <div className="left-img">
                <img src={require("../Assets/images/leftimg.png")} />
              </div>
            </Col>
            <Col lg={5} className="form-right">
              <div className="logo">
                <img src={require("../Assets/images/LOGO.png")} />
              </div>
              <div className="cmn-form-wrap">
                <div className="cmn-form">
                  <h2 className="heading-30 mb-2">Reset Password</h2>
                  <p className="sub-16 width-set">
                    Your new password must be unique from those previously used.
                  </p>
                  <div className="cmn-form-fields">
                    <Form.Group className="mb-3 form-inner">
                      <label>New Password</label>
                      <Form.Control
                        name="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        type="password"
                        placeholder="Enter here"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 form-inner">
                      <label>Confirm Password</label>
                      <Form.Control
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        type="password"
                        placeholder="Enter here"
                      />
                    </Form.Group>
                  </div>
                  <Link
                    // to="/otp"
                    onClick={handleSubmit}
                    className="cmn-green-btn mt-5 mb-4"
                  >
                
                    {resetisPending ? <div className="loader"></div> : "Submit"}
                  
                    
                    {/* {isPending ? <div className="loader"></div> : "Send Code"} */}
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        className="cmn-modal"
      >
        <Modal.Body>
          <div className="modal-inner">
            <img src={require("../Assets/images/tickmark.png")} />
            <h3>Password Changed</h3>
            <p>Your Password has been changed successfully!</p>
            <div className="d-flex justify-content-center">
              <Link to="/">Back to login</Link>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>

      {/* <img
        className="cmn-first-img"
        src={require("../Assets/images/donut.png")}
      />
      <img
        className="cmn-second-img"
        src={require("../Assets/images/cupcake.png")}
      />
      <img
        className="cmn-third-img"
        src={require("../Assets/images/cake.png")}
      /> */}
    </div>
  );
}
