import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function PlanReady() {
  return (
    <div className="cream-background">
      <Container>
        <div className="top-bar">
          <img src={require("../Assets/images/LOGO.png")} />
        </div>
        <div className="plan-box">
          <div className="plan-top">
            <Row>
              <Col lg={2}>
                <img src={require("../Assets/images/gymimg.png")} />
              </Col>
              <Col lg={10} className="d-flex align-items-center">
                <div>
                  <h3>Your Plan is Ready!</h3>
                  <p>We have selected this plan that suits you best </p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="plan-bottom">
            <Row className="align-items-center">
              <Col lg={6}>
                <h5>
                  Start your body toning journey to target all muscle groups and
                  builds your dream body.
                </h5>
              </Col>
              <Col lg={6}>
                <div className="plan-right">
                  <h6>Full body Challenge</h6>
                  <img src={require("../Assets/images/rectimg.png")} />
                </div>
              </Col>
              <Col lg={12}>
                <div className="plan-action-btns">
                  <Link
                    to="/full-body-challenge"
                    className="cmn-green-btn mt-5 mb-3"
                  >
                    Start Now
                  </Link>

                  <Link to="/" className="cmn-green-text">
                    Back to homepage
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
}
