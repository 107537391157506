import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { userprofileData } from "../redux/Reducer/profileCreation";

export default function Age() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileData = useSelector((state) => state.profilecreation.profileData);
  console.log(profileData, "profileDataq");

  useEffect(() => {
    if (profileData && profileData.birthDate) {
      setAge(profileData.birthDate);
    }
  }, [profileData]);
  // ======================================react state==================================================

  const [age, setAge] = useState(null);
  const [calculatedAge, setCalculatedAge] = useState(null);

  // ======================================calculate age===============================================

  useEffect(() => {
    if (age) {
      const birthDate = moment(age);
      const currentYear = moment();

      if (birthDate.isAfter(currentYear)) {
        toast.error("Birth date cannot be in the future!");
        setCalculatedAge(null); // reset calculated age
      } else {
        const ageInYears = currentYear.diff(birthDate, "years");
        setCalculatedAge(ageInYears);
      }
    }
  }, [age]);

  // =============================================handleSave==============================================

  const handlesave = () => {
    if (!age) {
      toast.error("Please select your Date of Birth");
    } else if (calculatedAge === null) {
      return
      // do nothing, don't navigate
    }else {
      dispatch(
        userprofileData({
          ...profileData,
          age: calculatedAge,
          birthDate: age,
        })
      );
      navigate("/focus-female");
    }
  };

  return (
    <div>
      <Container fluid>
        <div className="main-form">
          <Row>
            <Col lg={7} className="px-0">
              <div className="left-img">
                <img src={require("../Assets/images/leftimg.png")} />
              </div>
            </Col>
            <Col lg={5} className="form-right">
              <div className="logo">
                <img src={require("../Assets/images/LOGO.png")} />
              </div>
              <div className="cmn-form-wrap">
                <div className="cmn-form">
                  <h2 className="heading-30">What’s Your Age?</h2>
                  <div className="form-outer">
                    <div className="input-date">
                      <input
                        name="age"
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                        type="date"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div
                    // to="/focus-female"
                    style={{ cursor: "pointer" }}
                    onClick={handlesave}
                    className="cmn-green-btn mt-5 mb-4"
                  >
                    Next
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
