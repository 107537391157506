import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

export default function Footer() {
  // const location = useLocation();
  return (
    <>
      <footer
      // className={`main-container ${
      //   location.pathname === "/" ? "color-set" : "color-notset"
      // }`}
      >
        <div className="footer-top"></div>
        <div className="footer-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="footer-logo">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="155"
                    height="37"
                    viewBox="0 0 155 37"
                    fill="none"
                  >
                    <path
                      d="M0 29.1127V0H6.43289V23.7031H19.9627V29.1127H0Z"
                      fill="#D9DB26"
                    />
                    <path
                      d="M33.632 29.567C31.2248 29.567 29.1497 29.1403 27.4066 28.2868C25.6912 27.4059 24.3631 26.1808 23.4223 24.6116C22.5093 23.0149 22.0528 21.1429 22.0528 18.9955C22.0528 16.9033 22.4955 15.0725 23.3808 13.5033C24.2939 11.9066 25.5251 10.6678 27.0746 9.78683C28.6517 8.87835 30.4639 8.42411 32.5114 8.42411C35.4719 8.42411 37.8237 9.36012 39.5668 11.2321C41.3099 13.0766 42.1815 15.5818 42.1815 18.7478V20.317H28.0291C28.2505 21.9137 28.8315 23.0837 29.7722 23.827C30.7406 24.5428 32.0687 24.9007 33.7565 24.9007C34.8632 24.9007 35.9838 24.7355 37.1182 24.4051C38.2526 24.0748 39.2763 23.5655 40.1894 22.8772L41.8495 27.048C40.8257 27.8188 39.5668 28.4382 38.0727 28.9063C36.6063 29.3467 35.1261 29.567 33.632 29.567ZM32.7604 12.5536C31.4323 12.5536 30.3533 12.9528 29.5232 13.7511C28.7208 14.5495 28.2228 15.6644 28.0291 17.096H36.9937C36.8277 14.0677 35.4166 12.5536 32.7604 12.5536Z"
                      fill="#D9DB26"
                    />
                    <path
                      d="M55.6212 37C53.6567 37 51.8168 36.7935 50.1014 36.3806C48.4136 35.9676 46.9333 35.3207 45.6606 34.4397L47.3622 30.1864C48.4689 30.8746 49.714 31.3977 51.0974 31.7556C52.5085 32.141 53.8366 32.3337 55.0817 32.3337C58.6232 32.3337 60.394 30.7095 60.394 27.4609V25.1484C59.8406 26.1946 58.9691 27.0342 57.7793 27.6674C56.5896 28.3006 55.2892 28.6172 53.8781 28.6172C52.052 28.6172 50.461 28.2042 49.1053 27.3783C47.7495 26.5249 46.6981 25.3411 45.9511 23.827C45.2041 22.3129 44.8305 20.5372 44.8305 18.5C44.8305 16.4903 45.2041 14.7284 45.9511 13.2143C46.6981 11.7001 47.7495 10.5301 49.1053 9.70424C50.461 8.85082 52.052 8.42411 53.8781 8.42411C55.3445 8.42411 56.6588 8.7407 57.8208 9.37389C58.9829 10.0071 59.8406 10.8467 60.394 11.8929V8.83705H66.4949V26.7589C66.4949 30.1726 65.568 32.7329 63.7142 34.4397C61.8604 36.1466 59.1627 37 55.6212 37ZM55.7457 23.9509C57.1291 23.9509 58.2358 23.4691 59.0659 22.5056C59.9236 21.542 60.3525 20.2068 60.3525 18.5C60.3525 16.7932 59.9236 15.4717 59.0659 14.5357C58.2358 13.5722 57.1291 13.0904 55.7457 13.0904C54.3346 13.0904 53.214 13.5722 52.384 14.5357C51.5539 15.4717 51.1389 16.7932 51.1389 18.5C51.1389 20.2068 51.5539 21.542 52.384 22.5056C53.214 23.4691 54.3346 23.9509 55.7457 23.9509Z"
                      fill="#D9DB26"
                    />
                    <path
                      d="M81.155 37C79.1905 37 77.3506 36.7935 75.6351 36.3806C73.9474 35.9676 72.4671 35.3207 71.1944 34.4397L72.896 30.1864C74.0027 30.8746 75.2478 31.3977 76.6312 31.7556C78.0423 32.141 79.3704 32.3337 80.6154 32.3337C84.157 32.3337 85.9278 30.7095 85.9278 27.4609V25.1484C85.3744 26.1946 84.5029 27.0342 83.3131 27.6674C82.1234 28.3006 80.823 28.6172 79.4119 28.6172C77.5858 28.6172 75.9948 28.2042 74.6391 27.3783C73.2833 26.5249 72.2319 25.3411 71.4849 23.827C70.7378 22.3129 70.3643 20.5372 70.3643 18.5C70.3643 16.4903 70.7378 14.7284 71.4849 13.2143C72.2319 11.7001 73.2833 10.5301 74.6391 9.70424C75.9948 8.85082 77.5858 8.42411 79.4119 8.42411C80.8783 8.42411 82.1925 8.7407 83.3546 9.37389C84.5167 10.0071 85.3744 10.8467 85.9278 11.8929V8.83705H92.0286V26.7589C92.0286 30.1726 91.1018 32.7329 89.248 34.4397C87.3942 36.1466 84.6965 37 81.155 37ZM81.2795 23.9509C82.6629 23.9509 83.7696 23.4691 84.5997 22.5056C85.4574 21.542 85.8863 20.2068 85.8863 18.5C85.8863 16.7932 85.4574 15.4717 84.5997 14.5357C83.7696 13.5722 82.6629 13.0904 81.2795 13.0904C79.8684 13.0904 78.7478 13.5722 77.9178 14.5357C77.0877 15.4717 76.6727 16.7932 76.6727 18.5C76.6727 20.2068 77.0877 21.542 77.9178 22.5056C78.7478 23.4691 79.8684 23.9509 81.2795 23.9509Z"
                      fill="#D9DB26"
                    />
                    <path
                      d="M107.477 29.567C105.07 29.567 102.995 29.1403 101.252 28.2868C99.5365 27.4059 98.2084 26.1808 97.2677 24.6116C96.3546 23.0149 95.8981 21.1429 95.8981 18.9955C95.8981 16.9033 96.3408 15.0725 97.2262 13.5033C98.1392 11.9066 99.3705 10.6678 100.92 9.78683C102.497 8.87835 104.309 8.42411 106.357 8.42411C109.317 8.42411 111.669 9.36012 113.412 11.2321C115.155 13.0766 116.027 15.5818 116.027 18.7478V20.317H101.874C102.096 21.9137 102.677 23.0837 103.618 23.827C104.586 24.5428 105.914 24.9007 107.602 24.9007C108.709 24.9007 109.829 24.7355 110.964 24.4051C112.098 24.0748 113.122 23.5655 114.035 22.8772L115.695 27.048C114.671 27.8188 113.412 28.4382 111.918 28.9063C110.452 29.3467 108.971 29.567 107.477 29.567ZM106.606 12.5536C105.278 12.5536 104.199 12.9528 103.369 13.7511C102.566 14.5495 102.068 15.6644 101.874 17.096H110.839C110.673 14.0677 109.262 12.5536 106.606 12.5536Z"
                      fill="#D9DB26"
                    />
                    <path
                      d="M119.672 29.1127V8.83705H125.814V12.3471C126.783 9.97954 128.858 8.67188 132.04 8.42411L133.866 8.30022L134.239 13.5446L130.712 13.9163C127.613 14.2191 126.063 15.7883 126.063 18.6239V29.1127H119.672Z"
                      fill="#D9DB26"
                    />
                    <path
                      d="M143.462 29.567C141.941 29.567 140.599 29.2779 139.437 28.6998C138.274 28.1217 137.348 27.3371 136.656 26.346C135.992 25.3549 135.66 24.24 135.66 23.0011C135.66 21.542 136.047 20.372 136.822 19.4911C137.597 18.6101 138.856 17.9907 140.599 17.6328C142.342 17.2474 144.652 17.0547 147.53 17.0547H149.024V16.3527C149.024 15.1414 148.747 14.2879 148.194 13.7924C147.64 13.2693 146.699 13.0078 145.371 13.0078C144.265 13.0078 143.089 13.1868 141.844 13.5446C140.626 13.875 139.409 14.3843 138.191 15.0725L136.49 10.9018C137.209 10.4338 138.081 10.0071 139.105 9.62165C140.156 9.23624 141.249 8.94717 142.383 8.75447C143.518 8.53423 144.597 8.42411 145.62 8.42411C148.775 8.42411 151.126 9.13988 152.676 10.5714C154.225 11.9754 155 14.1641 155 17.1373V29.1127H149.148V26.0569C148.733 27.1306 148.028 27.984 147.032 28.6172C146.063 29.2504 144.873 29.567 143.462 29.567ZM144.873 25.3962C146.035 25.3962 147.018 24.997 147.82 24.1987C148.622 23.4003 149.024 22.3679 149.024 21.1016V20.2757H147.571C145.441 20.2757 143.933 20.4684 143.047 20.8538C142.162 21.2117 141.719 21.8449 141.719 22.7533C141.719 23.5242 141.982 24.1574 142.508 24.6529C143.061 25.1484 143.85 25.3962 144.873 25.3962Z"
                      fill="#D9DB26"
                    />
                  </svg>
                </div>
                <div className="footer-links">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <span>|</span>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy </Link>
                    </li>
                    <span>|</span>
                    {/* <li>
                      <Link to={!sessionStorage.getItem("token") ? "/login" : "/full-body-challenge"}>Start Workout</Link>
                    </li>
                    <span>|</span>
                    <li>
                      <Link to={!sessionStorage.getItem("token") ? "/login" : "/full-body-challenge-diet"}>Diet Plan</Link>
                    </li>
                    <span>|</span> */}
                    <li>
                      <Link to="/blogs-listing">Blog</Link>
                    </li>
                    <span>|</span>
                    <li>
                      <Link to="/terms-conditions">Terms of Service</Link>
                    </li>
                    <span>|</span>
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="footer-copyright">
          <ul>
            <li>
              <Link>Copyright by Leggera 2024</Link>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
}
