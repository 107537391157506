import React from "react";
import Layout from "../Components/Layout";
import { Col, Container, Row } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "react-router-dom";

export default function SingleDayDetails() {
  return (
    <Layout>
      <section className="body-challenge-banner">
        <Container className="mt-5">
          <div className="full-body-challenge-card">
            <Row className="align-items-center">
              <Col lg={7}>
                <div className="body-challenge-left">
                  <h2>Day 1</h2>
                  <p>Full Body Challenge</p>
                </div>
              </Col>
              <Col lg={5}>
                <div className="single-day-filter">
                  <p>11 Workouts</p>
                  <button>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M8.39162 10.8091C7.40786 10.8091 6.42409 10.8106 5.44033 10.807C5.33455 10.8066 5.28146 10.8325 5.2312 10.9365C4.90247 11.6162 4.35991 12.0002 3.59981 12C2.83959 11.9998 2.29773 11.6146 1.96871 10.9356C1.9184 10.8317 1.86618 10.8053 1.76082 10.8069C1.37677 10.8125 0.992529 10.8128 0.60846 10.8077C0.286813 10.8035 0.0371612 10.5767 0.00542869 10.272C-0.0268346 9.96237 0.157779 9.69362 0.458328 9.61881C0.511916 9.60547 0.569429 9.60412 0.62516 9.60395C1.01397 9.60273 1.40283 9.60042 1.79158 9.60548C1.87966 9.60664 1.91785 9.57855 1.95518 9.49774C2.26744 8.82185 2.79719 8.44422 3.54053 8.40125C4.25688 8.35984 4.93746 8.80124 5.2352 9.47973C5.27829 9.57791 5.32796 9.60592 5.43167 9.60573C7.41792 9.60243 9.4042 9.60302 11.3905 9.60364C11.6591 9.60374 11.8739 9.75462 11.9625 9.99949C12.0494 10.2396 11.9853 10.5235 11.7709 10.6657C11.6498 10.746 11.4874 10.8013 11.3429 10.803C10.3592 10.8147 9.37539 10.8091 8.39162 10.8091Z"
                        fill="#4B6C74"
                      />
                      <path
                        d="M8.41325 2.39636C7.42478 2.39637 6.4363 2.3978 5.44784 2.39438C5.33808 2.394 5.28203 2.41991 5.2292 2.53014C4.90254 3.21182 4.35505 3.59645 3.59848 3.59539C2.83626 3.59433 2.28746 3.20548 1.9613 2.51687C1.91429 2.41761 1.86561 2.39266 1.76499 2.39412C1.38092 2.39968 0.996718 2.39746 0.612592 2.39601C0.264369 2.3947 0.00161851 2.13721 7.24479e-06 1.79891C-0.00158507 1.46288 0.259478 1.1966 0.606924 1.19195C1.00506 1.18664 1.40333 1.18829 1.80148 1.19241C1.88388 1.19326 1.91977 1.16487 1.95504 1.09024C2.28274 0.397046 2.82556 0.000322846 3.59797 1.99325e-07C4.36978 -0.000322448 4.91638 0.391067 5.24013 1.08827C5.28347 1.18161 5.33893 1.19169 5.42386 1.19163C7.39612 1.19024 9.36837 1.19024 11.3406 1.19085C11.7403 1.19098 12.0188 1.46584 11.9956 1.83315C11.9752 2.15631 11.7221 2.39539 11.3927 2.39584C10.3996 2.39723 9.40642 2.39634 8.41325 2.39636Z"
                        fill="#4B6C74"
                      />
                      <path
                        d="M3.30511 5.39695C4.19533 5.39695 5.08553 5.39538 5.97572 5.39917C6.07441 5.39959 6.11926 5.36776 6.16246 5.27776C6.49182 4.59159 7.03342 4.20348 7.80112 4.20405C8.56279 4.2046 9.10253 4.58927 9.43004 5.26998C9.47949 5.37277 9.53388 5.40024 9.64035 5.39933C10.2119 5.39441 10.7836 5.3939 11.3551 5.39817C11.7703 5.40128 12.0356 5.69064 11.9907 6.07979C11.9596 6.34924 11.7477 6.57399 11.4749 6.59042C11.1761 6.60844 10.8757 6.60103 10.576 6.60242C10.2527 6.60392 9.92936 6.60582 9.60614 6.60079C9.51782 6.59942 9.47881 6.63022 9.44052 6.71028C9.10179 7.4182 8.54503 7.81225 7.75261 7.79517C7.01636 7.7793 6.49074 7.40021 6.17158 6.74195C6.117 6.62941 6.05873 6.59982 5.94102 6.60008C4.20278 6.60398 2.46453 6.60324 0.726291 6.60153C0.624174 6.60143 0.517736 6.59885 0.420776 6.57122C0.14214 6.49185 -0.0245409 6.22688 0.00497374 5.93333C0.0334268 5.65035 0.249204 5.42789 0.536559 5.40614C0.7323 5.39134 0.929746 5.39749 1.12643 5.39728C1.85266 5.39648 2.57889 5.39695 3.30511 5.39695Z"
                        fill="#4B6C74"
                      />
                    </svg>
                    Adjust
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="single-day-details-sec">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="single-day-card">
                <div className="left-side">
                  <div>
                    <img src={require("../Assets/images/workoutt.png")} />
                    <div className="video-detail">
                      <h3> Jumping Jacks</h3>
                      <p>00:20</p>
                    </div>
                  </div>
                  <Link to="/single-excersise-details">Start</Link>
                </div>
                <div className="right-side">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                  >
                    <path
                      d="M9.99191 2.1501C7.06273 2.1501 4.13359 2.15077 1.20441 2.1484C1.03342 2.14825 0.856936 2.1478 0.692521 2.10538C0.228746 1.98572 -0.0366775 1.53457 0.00410644 0.972528C0.042884 0.437868 0.357013 0.0746608 0.834382 0.0185348C0.950507 0.00490157 1.06812 0.000641184 1.18504 0.000604137C7.05899 -0.000210894 12.9329 -0.000359082 18.8069 0.00115984C18.9626 0.00119689 19.1218 0.00149326 19.2734 0.0339834C19.7386 0.133676 20.026 0.581758 19.9981 1.13942C19.9706 1.68998 19.6574 2.07078 19.1768 2.12954C19.0453 2.14562 18.9119 2.14925 18.7794 2.14928C15.8502 2.15036 12.9211 2.1501 9.99191 2.1501Z"
                      fill="#7D7D7D"
                    />
                    <path
                      d="M9.97544 8.5751C7.04626 8.5751 4.11712 8.57588 1.18795 8.5731C1.01737 8.57295 0.840609 8.56928 0.677439 8.52353C0.212765 8.39324 -0.0401712 7.94741 0.00573226 7.38C0.0486263 6.84949 0.365765 6.49518 0.850779 6.44172C0.967008 6.4289 1.08459 6.4256 1.20151 6.42557C7.06764 6.42482 12.9338 6.42457 18.7999 6.42668C18.9708 6.42675 19.1474 6.42783 19.3115 6.47128C19.7765 6.59446 20.0368 7.0418 19.9947 7.60699C19.955 8.14091 19.6408 8.50145 19.1604 8.55643C19.0288 8.57147 18.8955 8.57436 18.7629 8.57439C15.8338 8.57532 12.9046 8.5751 9.97544 8.5751Z"
                      fill="#7D7D7D"
                    />
                    <path
                      d="M10.0014 12.8499C12.9305 12.8499 15.8597 12.8494 18.7888 12.8513C18.9523 12.8514 19.1197 12.8509 19.2786 12.8858C19.7443 12.9882 20.0278 13.4367 19.9977 13.9952C19.9681 14.5456 19.6545 14.9221 19.1713 14.9803C19.0475 14.9952 18.9221 14.9993 18.7973 14.9994C12.9312 15.0002 7.06505 15.0005 1.19891 14.9984C1.02803 14.9983 0.851401 14.9969 0.687401 14.9533C0.223454 14.8301 -0.0379574 14.3803 0.00452154 13.8167C0.0447174 13.2834 0.35985 12.9225 0.839778 12.8682C0.971401 12.8533 1.10475 12.8506 1.23731 12.8506C4.1587 12.8497 7.08006 12.8499 10.0014 12.8499Z"
                      fill="#7D7D7D"
                    />
                  </svg>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="single-day-card">
                <div className="left-side">
                  <div>
                    <img src={require("../Assets/images/workoutt.png")} />
                    <div className="video-detail">
                      <h3> Jumping Jacks</h3>
                      <p>00:20</p>
                    </div>
                  </div>
                  <button>Start</button>
                </div>
                <div className="right-side">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                  >
                    <path
                      d="M9.99191 2.1501C7.06273 2.1501 4.13359 2.15077 1.20441 2.1484C1.03342 2.14825 0.856936 2.1478 0.692521 2.10538C0.228746 1.98572 -0.0366775 1.53457 0.00410644 0.972528C0.042884 0.437868 0.357013 0.0746608 0.834382 0.0185348C0.950507 0.00490157 1.06812 0.000641184 1.18504 0.000604137C7.05899 -0.000210894 12.9329 -0.000359082 18.8069 0.00115984C18.9626 0.00119689 19.1218 0.00149326 19.2734 0.0339834C19.7386 0.133676 20.026 0.581758 19.9981 1.13942C19.9706 1.68998 19.6574 2.07078 19.1768 2.12954C19.0453 2.14562 18.9119 2.14925 18.7794 2.14928C15.8502 2.15036 12.9211 2.1501 9.99191 2.1501Z"
                      fill="#7D7D7D"
                    />
                    <path
                      d="M9.97544 8.5751C7.04626 8.5751 4.11712 8.57588 1.18795 8.5731C1.01737 8.57295 0.840609 8.56928 0.677439 8.52353C0.212765 8.39324 -0.0401712 7.94741 0.00573226 7.38C0.0486263 6.84949 0.365765 6.49518 0.850779 6.44172C0.967008 6.4289 1.08459 6.4256 1.20151 6.42557C7.06764 6.42482 12.9338 6.42457 18.7999 6.42668C18.9708 6.42675 19.1474 6.42783 19.3115 6.47128C19.7765 6.59446 20.0368 7.0418 19.9947 7.60699C19.955 8.14091 19.6408 8.50145 19.1604 8.55643C19.0288 8.57147 18.8955 8.57436 18.7629 8.57439C15.8338 8.57532 12.9046 8.5751 9.97544 8.5751Z"
                      fill="#7D7D7D"
                    />
                    <path
                      d="M10.0014 12.8499C12.9305 12.8499 15.8597 12.8494 18.7888 12.8513C18.9523 12.8514 19.1197 12.8509 19.2786 12.8858C19.7443 12.9882 20.0278 13.4367 19.9977 13.9952C19.9681 14.5456 19.6545 14.9221 19.1713 14.9803C19.0475 14.9952 18.9221 14.9993 18.7973 14.9994C12.9312 15.0002 7.06505 15.0005 1.19891 14.9984C1.02803 14.9983 0.851401 14.9969 0.687401 14.9533C0.223454 14.8301 -0.0379574 14.3803 0.00452154 13.8167C0.0447174 13.2834 0.35985 12.9225 0.839778 12.8682C0.971401 12.8533 1.10475 12.8506 1.23731 12.8506C4.1587 12.8497 7.08006 12.8499 10.0014 12.8499Z"
                      fill="#7D7D7D"
                    />
                  </svg>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="single-day-card">
                <div className="left-side">
                  <div>
                    <img src={require("../Assets/images/workoutt.png")} />
                    <div className="video-detail">
                      <h3> Jumping Jacks</h3>
                      <p>00:20</p>
                    </div>
                  </div>
                  <button>Start</button>
                </div>
                <div className="right-side">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                  >
                    <path
                      d="M9.99191 2.1501C7.06273 2.1501 4.13359 2.15077 1.20441 2.1484C1.03342 2.14825 0.856936 2.1478 0.692521 2.10538C0.228746 1.98572 -0.0366775 1.53457 0.00410644 0.972528C0.042884 0.437868 0.357013 0.0746608 0.834382 0.0185348C0.950507 0.00490157 1.06812 0.000641184 1.18504 0.000604137C7.05899 -0.000210894 12.9329 -0.000359082 18.8069 0.00115984C18.9626 0.00119689 19.1218 0.00149326 19.2734 0.0339834C19.7386 0.133676 20.026 0.581758 19.9981 1.13942C19.9706 1.68998 19.6574 2.07078 19.1768 2.12954C19.0453 2.14562 18.9119 2.14925 18.7794 2.14928C15.8502 2.15036 12.9211 2.1501 9.99191 2.1501Z"
                      fill="#7D7D7D"
                    />
                    <path
                      d="M9.97544 8.5751C7.04626 8.5751 4.11712 8.57588 1.18795 8.5731C1.01737 8.57295 0.840609 8.56928 0.677439 8.52353C0.212765 8.39324 -0.0401712 7.94741 0.00573226 7.38C0.0486263 6.84949 0.365765 6.49518 0.850779 6.44172C0.967008 6.4289 1.08459 6.4256 1.20151 6.42557C7.06764 6.42482 12.9338 6.42457 18.7999 6.42668C18.9708 6.42675 19.1474 6.42783 19.3115 6.47128C19.7765 6.59446 20.0368 7.0418 19.9947 7.60699C19.955 8.14091 19.6408 8.50145 19.1604 8.55643C19.0288 8.57147 18.8955 8.57436 18.7629 8.57439C15.8338 8.57532 12.9046 8.5751 9.97544 8.5751Z"
                      fill="#7D7D7D"
                    />
                    <path
                      d="M10.0014 12.8499C12.9305 12.8499 15.8597 12.8494 18.7888 12.8513C18.9523 12.8514 19.1197 12.8509 19.2786 12.8858C19.7443 12.9882 20.0278 13.4367 19.9977 13.9952C19.9681 14.5456 19.6545 14.9221 19.1713 14.9803C19.0475 14.9952 18.9221 14.9993 18.7973 14.9994C12.9312 15.0002 7.06505 15.0005 1.19891 14.9984C1.02803 14.9983 0.851401 14.9969 0.687401 14.9533C0.223454 14.8301 -0.0379574 14.3803 0.00452154 13.8167C0.0447174 13.2834 0.35985 12.9225 0.839778 12.8682C0.971401 12.8533 1.10475 12.8506 1.23731 12.8506C4.1587 12.8497 7.08006 12.8499 10.0014 12.8499Z"
                      fill="#7D7D7D"
                    />
                  </svg>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="single-day-card">
                <div className="left-side">
                  <div>
                    <img src={require("../Assets/images/workoutt.png")} />
                    <div className="video-detail">
                      <h3> Jumping Jacks</h3>
                      <p>00:20</p>
                    </div>
                  </div>
                  <button>Start</button>
                </div>
                <div className="right-side">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                  >
                    <path
                      d="M9.99191 2.1501C7.06273 2.1501 4.13359 2.15077 1.20441 2.1484C1.03342 2.14825 0.856936 2.1478 0.692521 2.10538C0.228746 1.98572 -0.0366775 1.53457 0.00410644 0.972528C0.042884 0.437868 0.357013 0.0746608 0.834382 0.0185348C0.950507 0.00490157 1.06812 0.000641184 1.18504 0.000604137C7.05899 -0.000210894 12.9329 -0.000359082 18.8069 0.00115984C18.9626 0.00119689 19.1218 0.00149326 19.2734 0.0339834C19.7386 0.133676 20.026 0.581758 19.9981 1.13942C19.9706 1.68998 19.6574 2.07078 19.1768 2.12954C19.0453 2.14562 18.9119 2.14925 18.7794 2.14928C15.8502 2.15036 12.9211 2.1501 9.99191 2.1501Z"
                      fill="#7D7D7D"
                    />
                    <path
                      d="M9.97544 8.5751C7.04626 8.5751 4.11712 8.57588 1.18795 8.5731C1.01737 8.57295 0.840609 8.56928 0.677439 8.52353C0.212765 8.39324 -0.0401712 7.94741 0.00573226 7.38C0.0486263 6.84949 0.365765 6.49518 0.850779 6.44172C0.967008 6.4289 1.08459 6.4256 1.20151 6.42557C7.06764 6.42482 12.9338 6.42457 18.7999 6.42668C18.9708 6.42675 19.1474 6.42783 19.3115 6.47128C19.7765 6.59446 20.0368 7.0418 19.9947 7.60699C19.955 8.14091 19.6408 8.50145 19.1604 8.55643C19.0288 8.57147 18.8955 8.57436 18.7629 8.57439C15.8338 8.57532 12.9046 8.5751 9.97544 8.5751Z"
                      fill="#7D7D7D"
                    />
                    <path
                      d="M10.0014 12.8499C12.9305 12.8499 15.8597 12.8494 18.7888 12.8513C18.9523 12.8514 19.1197 12.8509 19.2786 12.8858C19.7443 12.9882 20.0278 13.4367 19.9977 13.9952C19.9681 14.5456 19.6545 14.9221 19.1713 14.9803C19.0475 14.9952 18.9221 14.9993 18.7973 14.9994C12.9312 15.0002 7.06505 15.0005 1.19891 14.9984C1.02803 14.9983 0.851401 14.9969 0.687401 14.9533C0.223454 14.8301 -0.0379574 14.3803 0.00452154 13.8167C0.0447174 13.2834 0.35985 12.9225 0.839778 12.8682C0.971401 12.8533 1.10475 12.8506 1.23731 12.8506C4.1587 12.8497 7.08006 12.8499 10.0014 12.8499Z"
                      fill="#7D7D7D"
                    />
                  </svg>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="single-day-card">
                <div className="left-side">
                  <div>
                    <img src={require("../Assets/images/workoutt.png")} />
                    <div className="video-detail">
                      <h3> Jumping Jacks</h3>
                      <p>00:20</p>
                    </div>
                  </div>
                  <button>Start</button>
                </div>
                <div className="right-side">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                  >
                    <path
                      d="M9.99191 2.1501C7.06273 2.1501 4.13359 2.15077 1.20441 2.1484C1.03342 2.14825 0.856936 2.1478 0.692521 2.10538C0.228746 1.98572 -0.0366775 1.53457 0.00410644 0.972528C0.042884 0.437868 0.357013 0.0746608 0.834382 0.0185348C0.950507 0.00490157 1.06812 0.000641184 1.18504 0.000604137C7.05899 -0.000210894 12.9329 -0.000359082 18.8069 0.00115984C18.9626 0.00119689 19.1218 0.00149326 19.2734 0.0339834C19.7386 0.133676 20.026 0.581758 19.9981 1.13942C19.9706 1.68998 19.6574 2.07078 19.1768 2.12954C19.0453 2.14562 18.9119 2.14925 18.7794 2.14928C15.8502 2.15036 12.9211 2.1501 9.99191 2.1501Z"
                      fill="#7D7D7D"
                    />
                    <path
                      d="M9.97544 8.5751C7.04626 8.5751 4.11712 8.57588 1.18795 8.5731C1.01737 8.57295 0.840609 8.56928 0.677439 8.52353C0.212765 8.39324 -0.0401712 7.94741 0.00573226 7.38C0.0486263 6.84949 0.365765 6.49518 0.850779 6.44172C0.967008 6.4289 1.08459 6.4256 1.20151 6.42557C7.06764 6.42482 12.9338 6.42457 18.7999 6.42668C18.9708 6.42675 19.1474 6.42783 19.3115 6.47128C19.7765 6.59446 20.0368 7.0418 19.9947 7.60699C19.955 8.14091 19.6408 8.50145 19.1604 8.55643C19.0288 8.57147 18.8955 8.57436 18.7629 8.57439C15.8338 8.57532 12.9046 8.5751 9.97544 8.5751Z"
                      fill="#7D7D7D"
                    />
                    <path
                      d="M10.0014 12.8499C12.9305 12.8499 15.8597 12.8494 18.7888 12.8513C18.9523 12.8514 19.1197 12.8509 19.2786 12.8858C19.7443 12.9882 20.0278 13.4367 19.9977 13.9952C19.9681 14.5456 19.6545 14.9221 19.1713 14.9803C19.0475 14.9952 18.9221 14.9993 18.7973 14.9994C12.9312 15.0002 7.06505 15.0005 1.19891 14.9984C1.02803 14.9983 0.851401 14.9969 0.687401 14.9533C0.223454 14.8301 -0.0379574 14.3803 0.00452154 13.8167C0.0447174 13.2834 0.35985 12.9225 0.839778 12.8682C0.971401 12.8533 1.10475 12.8506 1.23731 12.8506C4.1587 12.8497 7.08006 12.8499 10.0014 12.8499Z"
                      fill="#7D7D7D"
                    />
                  </svg>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="single-day-card">
                <div className="left-side">
                  <div>
                    <img src={require("../Assets/images/workoutt.png")} />
                    <div className="video-detail">
                      <h3> Jumping Jacks</h3>
                      <p>00:20</p>
                    </div>
                  </div>
                  <button>Start</button>
                </div>
                <div className="right-side">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                  >
                    <path
                      d="M9.99191 2.1501C7.06273 2.1501 4.13359 2.15077 1.20441 2.1484C1.03342 2.14825 0.856936 2.1478 0.692521 2.10538C0.228746 1.98572 -0.0366775 1.53457 0.00410644 0.972528C0.042884 0.437868 0.357013 0.0746608 0.834382 0.0185348C0.950507 0.00490157 1.06812 0.000641184 1.18504 0.000604137C7.05899 -0.000210894 12.9329 -0.000359082 18.8069 0.00115984C18.9626 0.00119689 19.1218 0.00149326 19.2734 0.0339834C19.7386 0.133676 20.026 0.581758 19.9981 1.13942C19.9706 1.68998 19.6574 2.07078 19.1768 2.12954C19.0453 2.14562 18.9119 2.14925 18.7794 2.14928C15.8502 2.15036 12.9211 2.1501 9.99191 2.1501Z"
                      fill="#7D7D7D"
                    />
                    <path
                      d="M9.97544 8.5751C7.04626 8.5751 4.11712 8.57588 1.18795 8.5731C1.01737 8.57295 0.840609 8.56928 0.677439 8.52353C0.212765 8.39324 -0.0401712 7.94741 0.00573226 7.38C0.0486263 6.84949 0.365765 6.49518 0.850779 6.44172C0.967008 6.4289 1.08459 6.4256 1.20151 6.42557C7.06764 6.42482 12.9338 6.42457 18.7999 6.42668C18.9708 6.42675 19.1474 6.42783 19.3115 6.47128C19.7765 6.59446 20.0368 7.0418 19.9947 7.60699C19.955 8.14091 19.6408 8.50145 19.1604 8.55643C19.0288 8.57147 18.8955 8.57436 18.7629 8.57439C15.8338 8.57532 12.9046 8.5751 9.97544 8.5751Z"
                      fill="#7D7D7D"
                    />
                    <path
                      d="M10.0014 12.8499C12.9305 12.8499 15.8597 12.8494 18.7888 12.8513C18.9523 12.8514 19.1197 12.8509 19.2786 12.8858C19.7443 12.9882 20.0278 13.4367 19.9977 13.9952C19.9681 14.5456 19.6545 14.9221 19.1713 14.9803C19.0475 14.9952 18.9221 14.9993 18.7973 14.9994C12.9312 15.0002 7.06505 15.0005 1.19891 14.9984C1.02803 14.9983 0.851401 14.9969 0.687401 14.9533C0.223454 14.8301 -0.0379574 14.3803 0.00452154 13.8167C0.0447174 13.2834 0.35985 12.9225 0.839778 12.8682C0.971401 12.8533 1.10475 12.8506 1.23731 12.8506C4.1587 12.8497 7.08006 12.8499 10.0014 12.8499Z"
                      fill="#7D7D7D"
                    />
                  </svg>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}
