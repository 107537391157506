import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import CountdownTimer from "../Components/Timer";
import Layout from "../Components/Layout";
import Slider from "react-slick";

export default function Home() {
  const targetDate = "2024-12-31T23:59:59";
  const settings = {
    className: "center",
    centerMode: true,

    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
  };
  const settingstwo = {
    className: "center",

    slidesToShow: 1,
    speed: 500,
  };

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <section className="banner-main">
        <Container>
          <div className="banner-inner">
            <h2>This summer train with</h2>
            <h3>
              <span>Leggera</span> Training
            </h3>
            <p>
              the fitness app created by Leggera that allows you to train
              online, wherever and whenever you want.
            </p>
            <div className="timer-box">
              <h6>$150 off your annual subscription until:</h6>
              <div className="mt-2 mb-4">
                <div className="timer-box-inner">
                  <CountdownTimer targetDate={targetDate} />
                </div>
              </div>
            </div>
            <Link to="#">Start Training Now</Link>
          </div>
        </Container>
      </section>
      <Container>
        <section className="sec-two">
          <Row>
            <Col lg={4}>
              <img src={require("../Assets/images/girl.png")} />
            </Col>
            <Col lg={8} className="d-flex align-items-center">
              <Row>
                <Col lg={12}>
                  <h2>4 Hours a week for a new life</h2>
                </Col>
                <Col lg={6}>
                  <h4>Programs for all levels</h4>
                  <p>
                    Whether you are a beginner or an experienced athlete on
                    Leggera you will find the program that best suits your needs
                    and level.
                  </p>
                </Col>
                <Col lg={6}>
                  <h4>A Person trainer at your home</h4>
                  <p>
                    In each workout our trainer will explain the execution of
                    the exercises to help you get the most out of every minute
                    of training.
                  </p>
                </Col>
                <Col lg={6} className="mt-3">
                  <h4>Train Wherever & Whatever you want</h4>
                  <p>
                    Each video is pre-recorded and you can follow it from any
                    device.
                  </p>
                </Col>
                <Col lg={6} className="mt-3">
                  <h4>4 hours a week are enough</h4>
                  <p>
                    All the workout programs you will find on Leggera will
                    require less than 4 hours a week.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="dumbell">
            <img src={require("../Assets/images/dumbell.svg").default} />
          </div>
        </section>
      </Container>
      <section className="third-sec">
        <Container>
          <div className="slider-inner">
            <Row>
              <Col lg={12}>
                <h3>Our Training Programs</h3>
              </Col>
              <div className="slider-container">
                <Slider {...settingstwo}>
                  <div>
                    <div className="train-slider">
                      <Row>
                        <Col lg={7} className="d-flex align-items-center">
                          <div className="left-slide">
                            <h4>Zero</h4>
                            <p>
                              The Zero Program is designed for anyone who has
                              not trained for a long time and wishes to
                              gradually resume their activity before starting
                              Leggera’s more challenging programs.
                            </p>
                            <Link to="#">Start Training Now</Link>
                          </div>
                        </Col>
                        <Col lg={5}>
                          <div className="right-slide">
                            <img
                              src={require("../Assets/images/fitness.png")}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div>
                    <div className="train-slider">
                      <Row>
                        <Col lg={7} className="d-flex align-items-center">
                          <div className="left-slide">
                            <h4>Zero</h4>
                            <p>
                              The Zero Program is designed for anyone who has
                              not trained for a long time and wishes to
                              gradually resume their activity before starting
                              Leggera’s more challenging programs.
                            </p>
                            <Link to="#">Start Training Now</Link>
                          </div>
                        </Col>
                        <Col lg={5}>
                          <div className="right-slide">
                            <img
                              src={require("../Assets/images/fitness.png")}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div>
                    <div className="train-slider">
                      <Row>
                        <Col lg={7} className="d-flex align-items-center">
                          <div className="left-slide">
                            <h4>Zero</h4>
                            <p>
                              The Zero Program is designed for anyone who has
                              not trained for a long time and wishes to
                              gradually resume their activity before starting
                              Leggera’s more challenging programs.
                            </p>
                            <Link to="#">Start Training Now</Link>
                          </div>
                        </Col>
                        <Col lg={5}>
                          <div className="right-slide">
                            <img
                              src={require("../Assets/images/fitness.png")}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div>
                    <div className="train-slider">
                      <Row>
                        <Col lg={7} className="d-flex align-items-center">
                          <div className="left-slide">
                            <h4>Zero</h4>
                            <p>
                              The Zero Program is designed for anyone who has
                              not trained for a long time and wishes to
                              gradually resume their activity before starting
                              Leggera’s more challenging programs.
                            </p>
                            <Link to="#">Start Training Now</Link>
                          </div>
                        </Col>
                        <Col lg={5}>
                          <div className="right-slide">
                            <img
                              src={require("../Assets/images/fitness.png")}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Slider>
              </div>
            </Row>
          </div>
        </Container>
      </section>
      <section className="fourth-sec">
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              <h2>Choose the Program</h2>
              <p>Are you want to change your life?</p>
            </Col>
            <Col lg={4}>
              <div className="blog-main">
                <img src={require("../Assets/images/dumbell.png")} />
                <h3>Strength Training</h3>
                <p>
                  Courses that focus on building muscle strength and power, such
                  as weightlifting and bodyweight exercises.
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="blog-main">
                <img src={require("../Assets/images/dumbel2.png")} />
                <h3>Cardiovascular Training</h3>
                <p>
                  Courses that improves cardiovascular endurance and help burn
                  fat, such as running, cycling, and high-intensity interval
                  training.
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="blog-main">
                <img src={require("../Assets/images/dumbel3.png")} />
                <h3>Gym Workout</h3>
                <p>
                  Courses that focus on flexibility, balance, and mindfulness,
                  using a combination of physical postures, breathing exercises,
                  & meditation
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="fourth-sec pt-2">
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              <h2>Check out Healthy Recipes</h2>
              <p>Balanced life with balanced diet</p>
            </Col>
            <Col lg={4}>
              <div className="blog-main">
                <img src={require("../Assets/images/salad.png")} />
                <div className="d-flex justify-content-between align-items-center">
                  <h3>Veg Salad</h3>
                  <h4>24 January 2024</h4>
                </div>
                <p>
                  Lorem Ipsum is simply dummy text for printing and typesetting
                  industry. Lorem ipsum has been industry’s standard dummy text.
                </p>
                <Link to="/single-excersise-details">View Recipe</Link>
              </div>
            </Col>
            <Col lg={4}>
              <div className="blog-main">
                <img src={require("../Assets/images/salad2.png")} />
                <div className="d-flex justify-content-between align-items-center">
                  <h3>Salad</h3>
                  <h4>24 January 2024</h4>
                </div>
                <p>
                  Courses that improves cardiovascular endurance and help burn
                  fat, such as running, cycling, and high-intensity interval
                  training.
                </p>
                <Link to="/single-excersise-details">View Recipe</Link>
              </div>
            </Col>
            <Col lg={4}>
              <div className="blog-main">
                <img src={require("../Assets/images/salad3.png")} />
                <div className="d-flex justify-content-between align-items-center">
                  <h3>Oats & Nuts</h3>
                  <h4>24 January 2024</h4>
                </div>
                <p>
                  Courses that focus on flexibility, balance, and mindfulness,
                  using a combination of physical postures, breathing exercises,
                  & meditation
                </p>
                <Link to="/single-excersise-details">View Recipe</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="fifth-sec">
        <Container>
          <h3>How does it works</h3>
          <h2>
            Buy a pack and receive a customized <br />
            workout plan just for you
          </h2>
          <p>
            After completing the registration, you can access the reserved area
            from your device and immediately start watching the videos that you
            will find in the different sections of the platform.
          </p>
          <Link to="#">Start Training Now</Link>
        </Container>
      </section>
      <section className="testimonial">
        <Container>
          <h2>What Our Clients Says About Us</h2>
          <div className="slider-container">
            <Slider {...settings}>
              <div>
                <div className="inner-slider">
                  <img
                    className="first-img"
                    src={require("../Assets/images/ashutosh-singh-rana-o6-TELiczj8-unsplash.png")}
                  />
                  <h3>Willow Luna</h3>
                  <img src={require("../Assets/images/stars.png")} />
                  <p>
                    Lorem Ipsum is simply dummy text for printing and
                    typesetting indsutry. Lorem Ipsum has been industry’s
                    standard dummy text ever since 1500s when an unknown printer
                    took galley of it and scrambled it to make a specimen copy.
                  </p>
                </div>
              </div>
              <div>
                <div className="inner-slider">
                  <img
                    className="first-img"
                    src={require("../Assets/images/ashutosh-singh-rana-o6-TELiczj8-unsplash.png")}
                  />
                  <h3>Willow Luna</h3>
                  <img src={require("../Assets/images/stars.png")} />
                  <p>
                    Lorem Ipsum is simply dummy text for printing and
                    typesetting indsutry. Lorem Ipsum has been industry’s
                    standard dummy text ever since 1500s when an unknown printer
                    took galley of it and scrambled it to make a specimen copy.
                  </p>
                </div>
              </div>
              <div>
                <div className="inner-slider">
                  <img
                    className="first-img"
                    src={require("../Assets/images/ashutosh-singh-rana-o6-TELiczj8-unsplash.png")}
                  />
                  <h3>Willow Luna</h3>
                  <img src={require("../Assets/images/stars.png")} />
                  <p>
                    Lorem Ipsum is simply dummy text for printing and
                    typesetting indsutry. Lorem Ipsum has been industry’s
                    standard dummy text ever since 1500s when an unknown printer
                    took galley of it and scrambled it to make a specimen copy.
                  </p>
                </div>
              </div>
              <div>
                <div className="inner-slider">
                  <img
                    className="first-img"
                    src={require("../Assets/images/ashutosh-singh-rana-o6-TELiczj8-unsplash.png")}
                  />
                  <h3>Willow Luna</h3>
                  <img src={require("../Assets/images/stars.png")} />
                  <p>
                    Lorem Ipsum is simply dummy text for printing and
                    typesetting indsutry. Lorem Ipsum has been industry’s
                    standard dummy text ever since 1500s when an unknown printer
                    took galley of it and scrambled it to make a specimen copy.
                  </p>
                </div>
              </div>
              <div>
                <div className="inner-slider">
                  <img
                    className="first-img"
                    src={require("../Assets/images/ashutosh-singh-rana-o6-TELiczj8-unsplash.png")}
                  />
                  <h3>Willow Luna</h3>
                  <img src={require("../Assets/images/stars.png")} />
                  <p>
                    Lorem Ipsum is simply dummy text for printing and
                    typesetting indsutry. Lorem Ipsum has been industry’s
                    standard dummy text ever since 1500s when an unknown printer
                    took galley of it and scrambled it to make a specimen copy.
                  </p>
                </div>
              </div>
              <div>
                <div className="inner-slider">
                  <img
                    className="first-img"
                    src={require("../Assets/images/ashutosh-singh-rana-o6-TELiczj8-unsplash.png")}
                  />
                  <h3>Willow Luna</h3>
                  <img src={require("../Assets/images/stars.png")} />
                  <p>
                    Lorem Ipsum is simply dummy text for printing and
                    typesetting indsutry. Lorem Ipsum has been industry’s
                    standard dummy text ever since 1500s when an unknown printer
                    took galley of it and scrambled it to make a specimen copy.
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </Container>
      </section>
      <section className="seven-sec">
        <Container>
          <h2 className="heading-faq">Frequently Asked Questions</h2>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                How we help lose fat without strict diets?
              </Accordion.Header>
              <Accordion.Body>
                To effectively lose fat without strict diets, Our app focuses on
                balanced nutrition that complements tailored exercises. By
                providing flexible meal plans rich in whole foods and aligning
                them with personalized workouts, users can achieve sustainable
                fat loss while enjoying their favorite foods in moderation.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {" "}
                What's the duration of this program?
              </Accordion.Header>
              <Accordion.Body>
                Lorem Ipsum is simply dummy text for printing and typesetting
                indsutry. Lorem Ipsum has been industry’s standard dummy text
                ever since 1500s when an unknown printer took galley of it and
                scrambled it to make a specimen copy.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                {" "}
                Will this program work for you?
              </Accordion.Header>
              <Accordion.Body>
                Lorem Ipsum is simply dummy text for printing and typesetting
                indsutry. Lorem Ipsum has been industry’s standard dummy text
                ever since 1500s when an unknown printer took galley of it and
                scrambled it to make a specimen copy.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                {" "}
                When will be the program revised?
              </Accordion.Header>
              <Accordion.Body>
                Lorem Ipsum is simply dummy text for printing and typesetting
                indsutry. Lorem Ipsum has been industry’s standard dummy text
                ever since 1500s when an unknown printer took galley of it and
                scrambled it to make a specimen copy.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                {" "}
                What if you don't find the program effective?
              </Accordion.Header>
              <Accordion.Body>
                Lorem Ipsum is simply dummy text for printing and typesetting
                indsutry. Lorem Ipsum has been industry’s standard dummy text
                ever since 1500s when an unknown printer took galley of it and
                scrambled it to make a specimen copy.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </section>
    </Layout>
  );
}
